import axios from "axios";

class Geocoder{
    getAddress(coords){
        const options = {
            method: 'GET',
            url: 'https://nominatim.openstreetmap.org/reverse',
            params: {
                lat: coords[0],
                lon: coords[1],
                polygon_threshold: '0.0',
                addressdetails:1,
                zoom:18,
                format:"json",
                "accept-language": "ru"
            }
        };
        return new Promise ((resolve, reject) => {
            axios.request(options).then(function (response) {
                resolve(response.data)
            }).catch(function (error) {
                reject(error)
            });
        })
    }
}

export const geocoder = new Geocoder()