import React, { useState } from "react";
import logo from "./../../source/svg/logo.svg";
import { useSearchParams } from "react-router-dom";
import { DoneForgetFetch } from "../connector/connectorV2";
import { useNavigate } from "react-router-dom";

export const ForgetDone = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [reg_data, set_reg_data] = useState({})
    const [bads, set_bads] = useState(null)
    const navigate = useNavigate()

    const UpdateRegData = (key, value) => {
        set_bads(null)
        let new_reg_data = {...reg_data}
        new_reg_data[key] = value
        set_reg_data(new_reg_data) 
    }
    const DoneForgetAction = () => {
        if (reg_data?.password != reg_data?.passsword_retry){
            set_bads("Пароли не совпадают")
            return
        }
        DoneForgetFetch({
            uid: searchParams.get("uid"),
            new_password: reg_data?.password
        }).then(res => {
            if (res.status == 200){
                navigate("/")
            } else {
                set_bads("Не удалось сменить пароль")
            }
        })
    }
    return (
        <>
            <div className="inviteHeader">
                <img className="header_img" src={logo} />
            </div>
            <div className="inviteContainer">
                {searchParams.get("uid") != null &&
                <div className="invTabCont">
                    <div className="invite_tabsContainer invite_size">
                        <div className="invite_tab">Восстановление пароля</div>
                    </div>
                    <div className="invite_inputContainer invite_size">
                        <input className="simple_input" type="password" value={reg_data.password} onChange={(e) => {UpdateRegData("password", e.target.value)}} placeholder="Новый пароль" />
                        <input className="simple_input" type="password" value={reg_data.passsword_retry} onChange={(e) => {UpdateRegData("passsword_retry", e.target.value)}} placeholder="Повторите пароль" />
                    </div>
                    { bads != null &&
                        <div className="red_bad_div">{bads}</div>
                    }
                    <div className="invite_row invite_size">
                        <div className="simple_btn" onClick={DoneForgetAction}>Сменить пароль</div>
                    </div>
                </div>}
            </div>
        </>
    )
}