import React, { useState, useEffect, useRef } from "react";
import "./newDeparture.css";
import Checkbox from "../../atoms/checkbox/checkbox";
import PopUp from "../../atoms/popUp/popUp";
import { partnerConnector } from "../../connector/connector";
import penSVG from "./../../../source/svg/pen.svg"
import { AddressPopUp, PickAddressPopUp } from "../../atoms/popUp/popUp";
import { postTypesVars, postmanDict } from "./constants";
import { geocoder } from "../../connector/geocoder";
import { useNavigate } from "react-router-dom";
import SettingsPopUp from "./settingsPopUp/settingsPopUp";
import { connect } from "react-redux";
import { mainMapper } from "../../../store/mappers/mainMapper";
import { mainDispatcher } from "../../../store/actions/mainActions";
import InputT from "../../atoms/inputT/InputT";

function NewDeparture(props){
    const [postType, setPostType] = useState("post");
    const [postCourVars, setPostCourVars] = useState([]);
    const [postPostVars, setPostPostVars] = useState([]);
    const [postPickupVars, setPostPickupVars] = useState([]);
    const [postOfficesList, setPostOfficesList] = useState([]);
    const navigate = useNavigate();
    const [depToconf, setDepToConf] = useState(null);


    const [address, setAddress] = useState("");
    const [partnerInitials, setPartnerInitials] = useState("");
    const [partnerPhone, setPartnerPhone] = useState("");
    //address
    const [city, setCity] = useState("");
    const [house, setHouse] = useState("");
    const [flat, setFlat] = useState("");

    const [is_free, set_is_free] = useState(false);
    const [postal_code, setPostalCode] = useState(null)

    const [popConf, setPopConf] = useState(false);
    const [inputToChange, setInputToChenge] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [needReload, setNeedReload] = useState(true);


    const [descriptionPopUp, setDescriptionPopUp] = useState(false);
    const [descriptionPopUpText, setDescriptionPopUpText] = useState(false);
    
    const [pickPopVisible, setPickPopVisible] = useState(false);
    const [pickPopLoading, setPickPopLoading] = useState(false);

    const commentRef = useRef(null)
    
    const [lat, setLat] = useState(null);
    const [lon, setLon] = useState(null);

    const [changeAllSettings, setChangeAllSettings] = useState(false)

    const [is_comment_hint, set_is_comment_hint] = useState(true)

    const [pickedPostAddress, setPickedPostAddress] = useState(null)

    useEffect(()=>{
        getSettings();
    }, [])
    
    useEffect(() => {
        setInputToChenge(null);
    }, [postType])

    useEffect(() => {
        setNeedReload(true);
    }, [address, postType, city, postal_code, flat, house])

    const getPostVars = () => {
        setIsLoading(true)
        let addressMain = getAddressString()
        const arrayToSend = {
            postal_code: postal_code, 
            type: postType, 
            postman: "all",
            full_address: addressMain,
        }
        partnerConnector.connect_async("getPostVariables", arrayToSend)
        .then(res => {
            if (postType == "courier"){
                setPostCourVars(res.data);
                setPostPostVars([])
                setPostPickupVars([])
            } else if (postType == "post"){
                setPostCourVars([])
                setPostPostVars(res.data);
                setPostPickupVars([])
            } else if (postType == "pickup"){
                setPostCourVars([])
                setPostPostVars([])
                setPostPickupVars(res.data);
            }
            setNeedReload(false);
            setIsLoading(false);
            if (postType == "post" || postType == "pickup"){
                partnerConnector.connect_async("getCoords", {address: addressMain})
                .then(res => {
                    console.log(res)
                    setLat(res.data.lat)
                    setLon(res.data.lon)
                })
            }   
            
        })
    }

    const getPostOffice = (item) => {
        setPickPopLoading(true)
        setPickPopVisible(true)
        let addressMain = getAddressString()
        const dictToSend = {
            postal_code: postal_code, 
            type: postType, 
            postman: postmanDict[item.postman], 
            lat: lat, lon: lon, 
            full_address: addressMain}
        console.log(lat, lon)
        partnerConnector.connect_async("getOffice", dictToSend)
        .then(res => {
            res = res.data;
            for (let i = 0; i < res.length; ++i){
                res[i].coords = [res[i].lat, res[i].lon]
                res[i].item = item
            }
            setPostOfficesList(res)
            setPickPopLoading(false)
        })
    }
    const getAddressString = (cityA = city, addressA = address, houseA = house, flatA = flat) => {
        return `г. ${cityA}, ${addressA}, д. ${houseA}, ${flatA}`
    }
    const getSettings = () => {
        partnerConnector.connect_async("getSettings")
        .then(res => {
            setPartnerInitials(res.data.name + " " + res.data.surname)
            setAddress(res.data.address)
            setPartnerPhone(res.data.phone)
            setCity(res.data.city)
            setPostalCode(res.data.postal_code)
            if(res.data.house) setHouse(res.data.house)
            if(res.data.flat) setFlat(res.data.flat)
            if (!res.data.city || !res.data.postal_code || !res.data.address || 
                !res.data.phone || !res.data.house || !res.data.flat){
                setChangeAllSettings(true)
            }
        })
    }

    const addNewDeparture = (selected_dep) => {
        let cur_address;
        if (postType == "courier"){
            cur_address = getAddressString()
        } else {
            cur_address = selected_dep.address
        }
        let dictToSend = {
            receiving_address: cur_address,
            receiving_city: postType == "courier" ? city : selected_dep.city,
            receiver_phone: partnerPhone,
            receiver_initials: partnerInitials,
            type: postType,
            partner_comment: commentRef.current.value,
            is_free: is_free,
            delivery_dates: `${selected_dep.dates}::${(selected_dep.time ? selected_dep.time : "")}`,
            price: selected_dep.price,
            postman: postmanDict[selected_dep.postman],
            postman_tarrif: selected_dep.tariff,
            deliviry_point_id: selected_dep.postman_id
        }
        partnerConnector.connect_async("addDeparture", dictToSend)
        .then(
            res => {
                setDescriptionPopUpText("Успешное создание")
                navigate("/partner/departures");
            },
            err => {
                setDescriptionPopUpText("Операция отклонена")
                setDescriptionPopUp(true)
            }
        )
    }

    const officeChoiceAction = (value) => {
        value.item.address = value.address;
        value.item.city = value.city;
        value.item["postman_id"] = value.id;
        setPickPopVisible(false)
    }

    const confirmFirtsAction = (departureInfo) => {
        if (!address || !partnerInitials || !partnerPhone || !city || !postal_code){
            setDescriptionPopUpText("Заполните все данные")
            setDescriptionPopUp(true)
            return;
        }

        setDepToConf(departureInfo)
        setPopConf(true);
    }
    const confirmLastAction = () => {
        addNewDeparture(depToconf)
        setPopConf(false);
    }
    const popUpExitAction = () => {
        setPopConf(false);
    }

    let postTypesItems = postTypesVars.map(value => <div className="radio-item"><div className={"simple-radio " + (postType==value.value ? "simple-radio-active" : "")} onClick={(e) => {setPostType(value.value)}}><div></div></div><div>{value.name}</div></div>)
    let posttamptVar;
    if (postType == "post" || postType == "pickup"){
        let curVars = (postType == "pickup" ? postPickupVars : postPostVars)
        posttamptVar = curVars.map( value => 
        <div className="dep-var-item">
            <div className="dep-var-item-row">
                <div className="dep-var-postman">Пункт выдачи {value.postman}</div>
                <div>{(is_free ? 0 : "~" + value.price)} р.</div>
            </div>
            
            
            <div className="dep-var-item-row">
                <div className="dep-var-item-column">
                    <div className="dep-var-item-text address_text_deps" onClick={(e) => {getPostOffice(value)}}>{value.address ? value.address : "Выберите адрес"}</div>
                    <div className="dep-var-item-text">{value.dates}</div>
                </div>
                <div className="dep-var-item-column dep-var-item-column-wrapper-small">
                    <div className={"simple_btn" + (value.address ? "" : " simple_btn_grey no_pointer")} onClick={(e) => {if (value.address) {setPickedPostAddress(value.address);confirmFirtsAction(value)}}}>Ок</div>
                </div>
            </div>
        </div>)
    } else if (postType == "courier"){
        posttamptVar = postCourVars.map(value => 
        <div className="dep-var-item">
            <div className="dep-var-header">{value.title}</div>
            <div className="dep-var-postman">{value.postman}</div>
            <div className="dep-var-item-row">
                <div className="dep-var-item-column">
                    <div className="dep-var-item-text">{value.dates}</div>
                    <div className="dep-var-item-text">{value.time}</div>
                </div>
                <div className="dep-var-item-column dep-var-item-column-wrapper-small">
                    <div className="simple_btn" onClick={(e) => {confirmFirtsAction(value)}}>{value.price} р.</div>
                </div>
            </div>
        </div>)
    }
    const reloadAction = () => {
        window.location.reload()
    }
    const logState = () => {
        console.log(props.picked_coords)
    }
    const setCoords = (picked) => {
        console.log(picked)
    }
    const validate = () => {
        
        if (
            !city || !postal_code || !address || 
                !partnerPhone || !house || !flat || !partnerInitials
        ) {
            return false
        }
        return true
    }
    let currentTypeText = "";
    postTypesVars.map(value => {if (postType==value.value) currentTypeText = value.name})
    let accept_pop = <><div>Подтвердите создание нового отправления</div>
    <div className="popAboutDepContainer">
        <div>Тип доставки: {currentTypeText},</div>
        <div>Адрес доставки: {(postType == "courier" ? `г. ${city}, ${address} ${flat}` : `г. ${city}, ${pickedPostAddress}`)}</div>
        <div>ФИО получателя: {partnerInitials}</div>
        <div>Телефон: {partnerPhone}</div>
        <div>Комментарий: {(commentRef.current ? commentRef.current.value : "")}</div>
        {is_free && <div>заказ более 500 пунктов</div>}
    </div>
    <div className="popRedText">Оплата за доставку взимается с Клиента при получении заказа</div>
    <div className="popRedText">* Срок указан без учета оформления заказа. Обработка заказа занимает 48ч.</div>
    <div className="popBlackText">Отследить заказ можно на закладке «Мои отправления»</div>
    </>
    return (
        <>
        <div className="newDepContainer">
            <div className="newDepSetContainer newDepHeight">
                <div className="simple_header">Оформление доставки</div>
                <InputT id="partnerInitials" name="ФИО получателя" active_input_new_dep={props.active_input_new_dep} value={partnerInitials} update={setPartnerInitials} set_cur_input={props.set_active_input_new_dep} />
                <InputT id="partnerPhone" name="Телефон" active_input_new_dep={props.active_input_new_dep} value={partnerPhone} update={setPartnerPhone} set_cur_input={props.set_active_input_new_dep} />
                <div className="relative">
                    {is_comment_hint && <div className="simple_hint_wrapper"><span className="small_text">укажите здесь ID партнера на который вы разместили заказ</span></div>}
                    <textarea ref={commentRef} className="simple_textarea" placeholder="Комментарий">
                    
                    </textarea>
                </div>
                <InputT id="cityI" name="Город" active_input_new_dep={props.active_input_new_dep} value={city} update={setCity} set_cur_input={props.set_active_input_new_dep} />
                <InputT id="addressI" name="Улица" active_input_new_dep={props.active_input_new_dep} value={address} update={setAddress} set_cur_input={props.set_active_input_new_dep} />
                <InputT id="houseI" name="Дом" active_input_new_dep={props.active_input_new_dep} value={house} update={setHouse} set_cur_input={props.set_active_input_new_dep} />
                <InputT id="flatI" name="Квартира" active_input_new_dep={props.active_input_new_dep} value={flat} update={setFlat} set_cur_input={props.set_active_input_new_dep} />
                <InputT id="indexI" name="Индекс" active_input_new_dep={props.active_input_new_dep} value={postal_code} update={setPostalCode} set_cur_input={props.set_active_input_new_dep} />
                <Checkbox cheked={is_free} action={set_is_free} after_text="У меня заказ болеее 500 пунктов — у меня бесплатная доставка" />
                
                
                
                <div className="newDepSetItem"><span className="blueText">Способ получения</span></div>
                {postTypesItems}
                <div className={"simple_btn mt-15 " + (!needReload || !(validate()) ? "simple_btn_grey no_pointer" : "")} onClick={(e) => {if (needReload && validate()){getPostVars()}}}>Рассчитать доставку</div>
                <div className="simple_btn mt-15" onClick={(e) => {navigate("/partner/departures")}}>Отслеживать отправления</div>
            </div>

            <div className="depVariablesList newDepHeight">
                {isLoading && <div className="sceleton"></div>}
                {posttamptVar}
            </div>

        </div>

        <PopUp visible={popConf} exit={popUpExitAction} action={confirmLastAction} btn_text={"Подтвердить"} text={accept_pop} />

        <PopUp visible={descriptionPopUp} exit={() => {setDescriptionPopUp(false)}} action={() => {setDescriptionPopUp(false)}} btn_text={"Подтвердить"} text={descriptionPopUpText} />
        <PickAddressPopUp coords={[lat, lon]} items={postOfficesList} loading={pickPopLoading} exit={() => {setPickPopVisible(false)}} visible={pickPopVisible} header="Выберите пункт выдачи" action={officeChoiceAction} />
        <SettingsPopUp exit={(e) => {setChangeAllSettings(false)}} visible={changeAllSettings} action={(e) => {reloadAction()}} /> 
        </>
    )
}


export default connect(mainMapper, mainDispatcher)(NewDeparture);