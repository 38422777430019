import React, { useState } from "react";
import "./checkbox.css";
import vect from "./vect.svg";
export default function Checkbox(props){
    const [checked, setChecked] = useState(props.checked || false);
    const checkAction = () => {
        if (props.action){
            props.action(!checked);
        }
        setChecked(!checked);
    }
    let checker = typeof(props.checked) == "boolean" ? props.checked : checked;
    return (
    <div className="checkBox_container">
        <div className="checkbox" onClick={(e) => {checkAction()}}>
            {checker && <img src={vect} />}
        </div>
        <div className="checkBox-text">{props.after_text}</div>
    </div>)
}