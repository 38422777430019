import "./settingsPopUp.css";
import React, {useState} from "react";
import { partnerConnector } from "../../../connector/connector";
import { AddressPopUp } from "../../../atoms/popUp/popUp";
import exitSVG from "./../../../../source/svg/exit.svg";

export default function SettingsPopUp(props){
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [postalCode, setPostalCode] = useState(null)
    const [city, setCity] = useState(null)
    const [house, setHouse] = useState("")
    const [flat, setFlat] = useState("")
    const updSettings = () => {
        const dict = {
            city: city,
            name: name,
            surname: surname,
            address: address,
            house: house,
            flat: flat,
            postal_code: postalCode,
            phone: phone
        }
        partnerConnector.connect_async("updateSettings", dict)
        .then(res => {
            localStorage.setItem("user_name", name)
            localStorage.setItem("user_surname", surname)
            if (props.action){
                props.action(dict)
            }
            props.exit()
        })
    }
    return(
        <>
        {props.visible &&
        <div className="popUpContainer">
            <div className="popUp editPopUp">
                <div className="exitRow"><img onClick={(e)=>{props.exit()}} src={exitSVG} /></div>
                <div className="center_row mb-40"><div className="simple_header">Сохранить изначальные настройки</div></div>
                <input className="simple_input" onChange={(e) => {setName(e.target.value)}} id="name123" value={name} placeholder="Имя" />
                <input className="simple_input" onChange={(e) => {setSurname(e.target.value)}} id="surname123" value={surname} placeholder="Фамилия" />
                <input className="simple_input" onChange={(e) => {setPhone(e.target.value)}} id="phone123" value={phone} placeholder="Телефон" />
                <div className="setRow">
                    <input className="simple_input" onChange={(e) => {setCity(e.target.value)}} id="city123" value={city} placeholder="Город" />
                    <input className="simple_input" onChange={(e) => {setPostalCode(e.target.value)}} id="index123" value={postalCode} placeholder="Индекс" />
                </div>
                <input className="simple_input" onChange={(e) => {setAddress(e.target.value)}} id="address123" value={address} placeholder="Адрес" />
                <div className="setRow">
                    <input className="simple_input" onChange={(e) => {setHouse(e.target.value)}} id="house123" value={house} placeholder="Дом" />
                    <input className="simple_input" onChange={(e) => {setFlat(e.target.value)}} id="flat123" value={flat} placeholder="Квартира" />
                </div>
                <div className="simple_btn" onClick={updSettings}>сохранить настройки</div>
            </div>
            <div className="greyBack"></div>
        </div>}
        </>
    )
}