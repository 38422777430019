import React from "react";
import { mainDispatcher } from "../../../store/actions/mainActions";
import { mainMapper } from "../../../store/mappers/mainMapper";
import { connect } from "react-redux";

import "./loader.css"

function Loader(props){
    return(
        <>
        
        {props.reload && 
            <div className="loader-container"><span class="loader"></span></div>}
        </>
    )
}


export default connect(mainMapper, mainDispatcher)(Loader);