import React, { useState, useRef, useEffect } from "react";
import "./administrators.css";
import Checkbox from "../../atoms/checkbox/checkbox";
import penSVG from "./../../../source/svg/pen.svg";
import { EditPopUp } from "../../atoms/popUp/popUp";
import { superAdminConnector } from "../../connector/connector";

export default function Administrators(props){
    const [adminList, setAdminList] = useState([])

    const [currentAdmin, setCurrentAdmin] = useState(null);
    const [editAdminPop, setEditAdminPop] = useState(false);
    const [checkedAdmins, setCheckedAdmins] = useState([]);

    const [addAdminPop, setAddAdminPop] = useState(false);

    useEffect(() => {
        getAdminsList()
    }, [])

    const getAdminsList = () => {
        superAdminConnector.connect_async("getAdministration")
        .then(res => {
            setAdminList(res.data)
        })
    }

    const addAdmin = () => {
        let adminData = {
            name: nameRefAdd.current.value,
            surname: surnameRefAdd.current.value,
            login: logRefAdd.current.value,
            password: passRefAdd.current.value
        }
        superAdminConnector.connect_async("createAdministration", adminData)
        .then(res => {
            getAdminsList()
            setAddAdminPop(false);
        })
    }

    const logRefAdd = useRef(null);
    const passRefAdd = useRef(null);
    const surnameRefAdd = useRef(null);
    const nameRefAdd = useRef(null);

    const logRef = useRef(null);
    const passRef = useRef(null);
    const surnameRef = useRef(null);
    const nameRef = useRef(null);

    const checkAll = (ch) => {
        if (ch) {
            let newChecked = [];
            adminList.map(value => {newChecked.push(value.id)})
            setCheckedAdmins(newChecked);
        } else {
            setCheckedAdmins([])
        }
    }

    const pushCheck = (id, ch) => {
        if (ch){
            if (checkedAdmins.includes(id)) return;
            setCheckedAdmins([...checkedAdmins, id]);
        } else {
            let newChecked = checkedAdmins.filter(value => value != id)
            setCheckedAdmins(newChecked);
        }
        
    }

    const openEditor = (curAdmin) => {
        setCurrentAdmin(curAdmin);
        setEditAdminPop(true);
    }

    const edit = () => {
        let dataToSend = {
            id: currentAdmin.id,
            name: nameRef.current.value,
            surname: surnameRef.current.value,
            password: passRef.current.value,
            login: currentAdmin.login
        }
        superAdminConnector.connect_async("editAdministration", dataToSend)
        .then(res => {
            setEditAdminPop(false);
            getAdminsList()
        })
    }

    const closeEditor = () => {
        setEditAdminPop(false);
    }

    const deleteAdmins = () => {
        superAdminConnector.connect_async("deleteAdministration", {list: checkedAdmins})
        .then(res => {
            setCheckedAdmins([])
        })
    }

    
    let counter = 0;
    let rows = adminList.map(value => {
        ++counter;
        return (
        <div className={"admins-table-tr admins-table-list " + (counter % 2 == 0 ? "admins-table-second" : "")}>
            <div><Checkbox checked={checkedAdmins.includes(value.id)} action={(ch) => {pushCheck(value.id, ch)}} /></div>
            <div>{value.name}</div>
            <div>{value.surname}</div>
            <div>{value.login}</div>
            <div><img className="cursorPointer" src={penSVG} onClick={(e) => {openEditor(value)}} /></div>
        </div>)}
        )
    
    let editFileds = 
    <>
        <div className="editPopRow">
            <input className="simple_input" ref={nameRef} type="text" value={currentAdmin?.name} placeholder="Имя"/>
            <input className="simple_input" ref={surnameRef} type="text" value={currentAdmin?.surname} placeholder="Фамилия" />
        </div>
        <div className="editPopRow">
            <input className="simple_input" ref={logRef} type="text" disabled value={currentAdmin?.login} placeholder="Логин" />
            <input className="simple_input" ref={passRef} type="password" placeholder="Пароль" />
        </div>
    
    </>
    let addFields = 
    <>
        <div className="editPopRow">
            <input className="simple_input" ref={nameRefAdd} type="text" placeholder="Имя" />
            <input className="simple_input" ref={surnameRefAdd} type="text" placeholder="Фамилия" />
        </div>
        <div className="editPopRow">
            <input className="simple_input" ref={logRefAdd} type="text" placeholder="Логин" />
            <input className="simple_input" ref={passRefAdd} type="password" placeholder="Пароль" />
        </div>
    </>
    return (
    <div className="adminsListContainer">
        <div className="between_row">
            <div className="simple_header">Администраторы</div>
            <div className="simple_btn simple_btn_small" onClick={(e) => {setAddAdminPop(true)}}>+ Добавить администратора</div>
        </div>
        <div className="admins-table">
            <div className="admins-table-tr admins-table-list admins-table-head">
                <div><Checkbox action={(ch) => {checkAll(ch)}} /></div>
                <div>Имя </div>
                <div>Фамилия</div>
                <div>Логин</div>
                <div><img src={penSVG} /></div>
            </div>
            {rows}
            {checkedAdmins.length != 0 &&
            <div className="admin-table-btnsContainer">
                <div className="simple_btn simple_btn_grey" onClick={(e) => {setCheckedAdmins([])}}>Отменить</div>
                <div className="simple_btn" onClick={(e) => {deleteAdmins()}}>Удалить</div>
            </div>}
        </div>
        {editAdminPop && 
            <EditPopUp fields={editFileds} btn_text="Сохранить данные" header="Данные администратора" action={edit} exit={closeEditor} visible={editAdminPop} />}
        {addAdminPop && 
            <EditPopUp fields={addFields} btn_text="Сохранить" header="Добавить администратора" action={addAdmin} exit={() => {setAddAdminPop(false)}} visible={addAdminPop} />}
        
    </div>
    )
}