import React, { useEffect, useState, useRef } from "react";
import { partnerConnector } from "../../connector/connector";
import { AddressPopUp } from "../../atoms/popUp/popUp";
import "./settings.css";

export default function Settings(props){
    const [settings, setSettings] = useState({})
    const [ changed, setChanged ] = useState(false)
    const oldPassRef = useRef();
    const newPassRef = useRef();
    const newPassRepeatRef = useRef();
    useEffect(() => {
        partnerConnector.connect_async("getSettings")
        .then(res => {
            setSettings(res.data)
        })
    }, [])
    const chengeSettings = () => {
        if (!changed) return
        partnerConnector.connect_async("updateSettings", settings)
        .then(res => {
            localStorage.setItem("user_name", settings["name"])
            localStorage.setItem("user_surname", settings["surname"])
            setChanged(false)
        })
    }
    const change_password = () => {
        newPassRef.current.style.border = "1px solid #004B8D";
        newPassRepeatRef.current.style.border = "1px solid #004B8D";
        let newPass = newPassRef.current.value;
        let oldPass = oldPassRef.current.value;
        let newPassRepeat = newPassRepeatRef.current.value;
        if (newPass != newPassRepeat){
            newPassRef.current.style.border = "1px solid red";
            newPassRepeatRef.current.style.border = "1px solid red";
        }
        let data = {
            old_password: oldPass,
            new_password: newPass,
        }
        partnerConnector.connect_async("changePassword", data)
        .then(res => {
            newPassRef.current.value = "";
            oldPassRef.current.value = "";
            newPassRepeatRef.current.value = "";
            console.log("Успешно");
        })
    }
    const chengeSettingsInput = (settingType, newValue) => {
        let newSettings = {}
        Object.assign(newSettings, settings)
        newSettings[settingType] = newValue;
        setChanged(true)
        setSettings(newSettings)
    }

    const changeSettingsMany = (newSet) => {
        let newSettings = {}
        Object.assign(newSettings, settings)
        newSet.forEach(value => {newSettings[value.name] = value.value})
        setChanged(true)
        setSettings(newSettings)
    }
    return (
        <>
        <div className="setContainer">
            <div className="selfSetContainer setCont">
                <div className="setTitle">Личные данные</div>
                <div className="setRow">
                    <input className="simple_input" onChange={(e) => {chengeSettingsInput("name", e.target.value)}} id="name" value={settings.name} placeholder="Имя" />
                    <input className="simple_input" onChange={(e) => {chengeSettingsInput("email", e.target.value)}} id="email" value={settings.email} placeholder="E-mail" />
                </div>
                <div className="setRow">
                    <input className="simple_input" onChange={(e) => {chengeSettingsInput("surname", e.target.value)}} id="surname" value={settings.surname} placeholder="Фамилия" />
                    <input className="simple_input" onChange={(e) => {chengeSettingsInput("phone", e.target.value)}} id="phone" value={settings.phone} placeholder="Телефон" />
                </div>
                <div className="setRow">
                <input className="simple_input" onChange={(e) => {chengeSettingsInput("city", e.target.value)}} id="city" value={settings.city} placeholder="Город" />
                    <input className="simple_input" onChange={(e) => {chengeSettingsInput("postal_code", e.target.value)}} id="postal_code" value={settings.postal_code} placeholder="Индекс" />
                </div>
                <input className="simple_input" onChange={(e) => {chengeSettingsInput("address", e.target.value)}}  id="address" value={settings.address} placeholder="Улица" />
                <div className="setRow">
                    <input className="simple_input" onChange={(e) => {chengeSettingsInput("house", e.target.value)}} id="house" value={settings.house} placeholder="Дом" />
                    <input className="simple_input" onChange={(e) => {chengeSettingsInput("flat", e.target.value)}} id="flat" value={settings.flat} placeholder="Квартира" />
                </div>
                
                <div className={"simple_btn " + (!changed ? "simple_btn_grey" : "")} onClick={chengeSettings}>Сохранить данные</div>
            </div>
            <div className="passSetContainer setCont">
                <div className="setTitle">Пароль</div>
                <input className="simple_input" ref={oldPassRef} id="oldPass" placeholder="Старый пароль" />
                <input className="simple_input" ref={newPassRef} id="newPass" placeholder="Новый пароль" />
                <input className="simple_input" ref={newPassRepeatRef} id="newPassRep" placeholder="Повторите пароль" />
                <div className="simple_btn" onClick={(e) => {change_password()}}>Изменить пароль</div>
            </div>
        </div>
        </>
    )
}