export const aplication_types = [
    {label: "Повреждение (товара)", value: "Повреждение (товара)"},
    {label: "Нехватка", value: "Нехватка"},
    {label: "Перепутали товар", value: "Перепутали товар"},
    {label: "Долгая доставка", value: "Долгая доставка"},
    {label: "Чужой заказ", value: "Чужой заказ"},
    {label: "Срок годности", value: "Срок годности"},
    {label: "Заводской брак", value: "Заводской брак"},
    {label: "Другой вид претензии", value: "Другой вид претензии"},
    {label: "Предложение по улучшению сервиса", value: "Предложение по улучшению сервиса"},
]