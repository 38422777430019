import React, { useState, useEffect } from "react";
import { Button, Divider, Input, Stack, Popover, Message, Panel, Placeholder } from "rsuite";

function CommentsModal({item, add_comment, get_id_func, get_comments_func, get_item_fetch}){
    const [comments, set_comments] = useState(get_comments_func(item))
    const [comment_text, set_comment_text] = useState("")
    const addCommentAction = () => {
        if (comment_text.trim() == "") return;
        if (add_comment == null) return;
        const id = get_id_func(item)
        add_comment({id:id, text: comment_text.trim()}).then(res=> {
            set_comment_text("")
            getCommentsAction()
        })
    }
    const getCommentsAction = () => {
        get_item_fetch({id: get_id_func(item)}).then(res => {
            set_comments(get_comments_func(res.data[0]))
        })
    }
    const UserMeassage = ({message}) => {
        return (
            <div className="simple_message_panel">
                <div className="simple_message_panel_title">
                    <div className="simple_message_panel_title_title">
                        {message.author_name}
                    </div>
                    <div>
                        {new Date(message.create_datetime).toLocaleString()}
                    </div>
                </div>
                <div className="simple_message_panel_text">{message.text}</div>
            </div>
        )
    }

    const SystemMessage = ({message}) => {
        const [wrapped, setWrapped] = useState(true)
        return (
            <div>
                <div className={"system_msg_wrapper " + (wrapped ? "system_msg_wrapper_wrapped" : "")}>{new Date(message.create_datetime).toLocaleString()}{"   "}{message.text}</div>
                <div className="system_msg_wrapped_btn" onClick={(e) => {setWrapped(!wrapped)}}>{wrapped ? "развернуть" : "свернуть"}</div>
            </div>
        )
    }

    const GetMessage = (message) => {
        switch (message.type){
            case "user":
                return <UserMeassage message={message} />
            case "system":
                return <SystemMessage message={message} />
            default:
                return <></>
        }
    }
    return (
        <Stack direction="column" className="owl_message_wrapper">
            <Stack.Item className="owl_message_wrapper_item">
            <div >
                <div className="message_panel scroll_stylle">
                    {comments.map(value => <>{GetMessage(value)}<hr/></>)}
                </div>
                <Divider />
                <Stack spacing={10} alignItems="center" style={{width: "100%"}}>
                    <Stack.Item style={{width: "100%"}}><Input style={{width: "100%"}} value={comment_text} onChange={(value) => {set_comment_text(value)}} placeholder="введите сообщение" /></Stack.Item>
                    
                    <Button onClick={addCommentAction}>Send</Button>
                </Stack>
            </div>
            </Stack.Item>
            
        </Stack>
    )
}
export default CommentsModal;