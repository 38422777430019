import React, { useRef, useState, useEffect } from "react"
import { EditPopUp } from "../../atoms/popUp/popUp";
import Checkbox from "../../atoms/checkbox/checkbox";
import penSVG from "./../../../source/svg/pen.svg";
import { adminConnector } from "../../connector/connector";
import "./departures.css"
import { connect } from "react-redux";
import { mainDispatcher } from "../../../store/actions/mainActions";
import { mainMapper } from "../../../store/mappers/mainMapper";

const departTest = [
    {id: 1, orderDate: "17-12-2023", deliviryDate: "18-12-2023", address: "ул. Ленина д. 77", status: "Доставлен", trek: "12-12-12123123", docsUrl: "test.test/url1"},
    {id: 2, orderDate: "17-12-2023", deliviryDate: "18-12-2023", address: "ул. Ленина д. 77", status: "Доставлен", trek: "12-12-12123123", docsUrl: "test.test/url1"},
    {id: 3, orderDate: "17-12-2023", deliviryDate: "18-12-2023", address: "ул. Ленина д. 77", status: "Доставлен", trek: "12-12-12123123", docsUrl: "test.test/url1"},
]


function Departures(props){
    const [curTab, setCurTab] = useState("toConfirm")

    const [subTab, setSubTab] = useState("sdek")

    const [list, setList] = useState(departTest)

    const [curentItem, setCurentItem] = useState(null);
    const [editItemPop, setEditItemPop] = useState(false);
    const [checkedAdmins, setCheckedAdmins] = useState([]);

    const cityRef = useRef();
    const addressRef = useRef();
    const initialsRef = useRef();
    const phoneRef = useRef();
    const freeRef = useRef();
    const commentRef = useRef();
    const statusRef = useRef();
    const generated_num_ref = useRef();

    useEffect(()=>{
        getDepartures(curTab)
    }, [])

    const getDepartures = (confirm, postman = subTab) => {
        let dictToSend = {
            "is_confirmed": confirm == "toConfirm" ? false : true
        }
        if (confirm != "toConfirm"){ 
            dictToSend["postman"] = postman;
        }
        props.set_reload(true)
        adminConnector.connect_async("getDepartures", dictToSend)
        .then(res => {
            setList(res.data);
            props.set_reload(false)
        })
    }

    const checkAll = (ch) => {
        if (ch) {
            let newChecked = [];
            list.map(value => {newChecked.push(value.id)})
            setCheckedAdmins(newChecked);
        } else {
            setCheckedAdmins([])
        }
    }

    const pushCheck = (id, ch) => {
        if (ch){
            if (checkedAdmins.includes(id)) return;
            setCheckedAdmins([...checkedAdmins, id]);
        } else {
            let newChecked = checkedAdmins.filter(value => value != id)
            setCheckedAdmins(newChecked);
        }
        
    }

    const openEditor = (curAdmin) => {
        setCurentItem(curAdmin);
        setEditItemPop(true);
    }

    const edit = () => {
        let is_free_value = freeRef?.current.value == "Да" ? true : false;
        let dictToSend = {
            list: [curentItem.id],
            receiving_address: addressRef?.current.value,
            receiving_city: cityRef?.current.value,
            receiver_phone: phoneRef?.current.value,
            status: statusRef?.current.value,
            partner_comment: commentRef?.current.value,
            is_free: is_free_value,
            generated_number: generated_num_ref?.current.value,
        }
        props.set_reload(true)
        adminConnector.connect_async("editDepartures", dictToSend)
        .then(res => {
            props.set_reload(false)
            setEditItemPop(false);
            getDepartures(curTab)
        })
    }

    const closeEditor = () => {
        setEditItemPop(false);
    }

    const changeSubTab = (tab) => {
        setSubTab(tab)
        getDepartures(curTab, tab)
        setCheckedAdmins([])
    }

    const changeTab = (tab) => {
        setCurTab(tab)
        getDepartures(tab)
        setCheckedAdmins([])
    }
    
    const confirmDepartures = () => {
        props.set_reload(true)
        adminConnector.connect_async("confirmDepartures", {list: checkedAdmins})
        .then(res => {
            props.set_reload(false)
            setCheckedAdmins([])
            getDepartures(curTab)
        })
    }

    const deleteDepartures = () => {
        props.set_reload(true)
        adminConnector.connect_async("removeDepartures", {list: checkedAdmins})
        .then(res => {
            props.set_reload(false)
            setCheckedAdmins([])
            getDepartures(curTab)
        })
    }

    const save_receipt = () => {
        alert("Документ не доступен")
    }
    
    const getPapper = (type) => {
        let orderList = []
        for (let i = 0; i < checkedAdmins.length; ++i){
            for (let j = 0; j < list.length; ++j){
                if (checkedAdmins[i] == list[j].id){
                    orderList.push(list[j].postman_id)
                }
            }
        }
        props.set_reload(true)
        adminConnector.get_gile(type, {orders: orderList, postman: subTab}, 1)
        .then(res => {
            props.set_reload(false)
            console.log(res)
            let link = document.createElement('a');
            link.download = 'doc.pdf';
            let file = new Blob([res], {type: 'application/json'});
            link.href = URL.createObjectURL(file);
            link.click();
            URL.revokeObjectURL(link.href)
            
        })
    }

    let editFileds = 
    <>  
        <div className="editPopRow">
            <div className="simple_input_wrapper">
                <input ref={cityRef} className="simple_input" type="text" defaultValue={curentItem?.receiving_city} placeholder="Город получения"/>
                <div className="simple_input_title">Город получения</div>
            </div>
            <div className="simple_input_wrapper">
                <input ref={addressRef} className="simple_input" type="text" defaultValue={curentItem?.receiving_address} placeholder="Адрес получения"/>
                <div className="simple_input_title">Адрес получения</div>
            </div>
        </div>
        <div className="simple_input_wrapper">
            <input ref={initialsRef} disabled={true} className="simple_input" type="text" defaultValue={curentItem?.receiver_initials} placeholder="Получатель"/>
            <div className="simple_input_title">Получатель</div>
        </div>
        <div className="simple_input_wrapper">
            <input ref={generated_num_ref} disabled={false} className="simple_input" type="text" defaultValue={curentItem?.generated_number} placeholder="Id отправителя"/>
            <div className="simple_input_title">Id отправителя</div>
        </div>
        <div className="editPopRow">
            <div className="simple_input_wrapper">
                <input ref={phoneRef} className="simple_input" disabled={false} type="text" defaultValue={curentItem?.receiver_phone} placeholder="Телефон получателя"/>
                <div className="simple_input_title">Телефон получателя</div>
            </div>
            
            <div className="simple_input_wrapper">
                <select className="simple_input" ref={freeRef}>
                    <option selected={curentItem?.is_free ? true : false}>Да</option>
                    <option selected={curentItem?.is_free ? false : true}>Нет</option>
                </select>
                <div className="simple_input_title">Бесплатная для получателя</div>
            </div>
            
            
        </div>
        <div className="simple_input_wrapper">
            <input ref={statusRef} className="simple_input" type="text" defaultValue={curentItem?.status} placeholder="Статус"/>
            <div className="simple_input_title">Статус</div>
        </div>
        <div className="simple_input_wrapper">
            <input ref={commentRef} className="simple_input" type="text" defaultValue={curentItem?.partner_comment} placeholder="Комментарий отправителя"/>
            <div className="simple_input_title">Комментарий отправителя</div>
        </div>
    
    </>

    let counter = 0;
    let rows = list.map(value => {
        ++counter;
        return (
        <div className={"admins-table-tr deps-table-list " + (counter % 2 == 0 ? "admins-table-second" : "")}>
            <div><Checkbox checked={checkedAdmins.includes(value.id)} action={(ch) => {pushCheck(value.id, ch)}} /></div>
            <div>{new Date(value.order_date).toLocaleString()}</div>
            <div>{value.receive_date ? new Date(value.receive_date).toLocaleString() : "-"}</div>
            <div>{value.receiving_address}</div>
            <div>{value.owner}</div>
            <div>{value.status}</div>
            <div>{value.postman}</div>
            <div>{value.type}</div>
            <div>{value.trek ? value.trek : "-"}</div>
            <div>{value.receipt_id && <a target="_blank" onClick={(e) => {save_receipt(value.receipt_id)}} className="doc_link">Скачать</a>}</div>
            <div>{!value.is_confirmed && <img className="cursorPointer" src={penSVG} onClick={(e) => {openEditor(value)}} />}</div>
        </div>)}
        )
    return (
    <div className="adminsListContainer">
        <div className="between_row">
            <div className="simple_header">Отправления</div>
            <div className="enveloper_center">
                <div className={"nav-item " + (curTab == "toConfirm" ? "nav-item_active" : "")} onClick={(e)=>{changeTab("toConfirm")}}>Не подтвержденные</div>
                <div className={"nav-item " + (curTab == "confirmed" ? "nav-item_active" : "")} onClick={(e)=>{changeTab("confirmed")}}>Подтвержденные</div>
            </div>
            {
                curTab == "confirmed" &&
                <div className="enveloper_center">
                    <div className={"nav-item " + (subTab == "sdek" ? "nav-item_active" : "")} onClick={(e)=>{changeSubTab("sdek")}}>Сдек</div>
                    <div className={"nav-item " + (subTab == "yandex" ? "nav-item_active" : "")} onClick={(e)=>{changeSubTab("yandex")}}>Яндекс</div>
                </div>
            }
        </div>

        <div className="admins-table">
            <div className="admins-table-tr deps-table-list admins-table-head">
                <div><Checkbox action={(ch) => {checkAll(ch)}} /></div>
                <div>Дата заказа</div>
                <div>Дата доставки</div>
                <div>Адрес доставки</div>
                <div>Id парнера</div>
                <div>Статус</div>
                <div>Исполнитель</div>
                <div>Тип доставки</div>
                <div>Трек номер</div>
                <div>Документ</div>
                <div><img src={penSVG} /></div>
            </div>
            {rows}
            {checkedAdmins.length != 0 &&
            <div className="admin-table-btnsContainer">
                <div className="simple_btn simple_btn_grey" onClick={(e) => {setCheckedAdmins([])}}>Отменить</div>
                <div className="simple_btn" onClick={(e) => {deleteDepartures()}}>Удалить</div>
                {curTab == "confirmed" && <div className="simple_btn" onClick={(e) => {getPapper("getQr")}}>скачать ШК</div>}
                {curTab == "confirmed" && <div className="simple_btn" onClick={(e) => {getPapper("getReceipts")}}>скачать квитанцию</div>}
                {curTab == "toConfirm" && <div className="simple_btn" onClick={(e) => {confirmDepartures()}}>Подтвердить</div>}
            </div>}
        </div>
        {editItemPop && 
            <EditPopUp fields={editFileds} btn_text="Изменить данные" header="Данные отправления" action={edit} exit={closeEditor} visible={editItemPop} />}
        
    </div>
    )
}

export default connect(mainMapper, mainDispatcher)(Departures);