const initialState = {
    pickedCoords: [55.75, 37.57],
};

export function newDepReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_PICKED_COORDS_NEW_DEP':
            return { ...state, pickedCoords: action.payload };
        default:
            return state;
    }
}