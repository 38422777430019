import React, { useEffect, useState } from "react";
import { CompactTable } from '@table-library/react-table-library/compact';
import { Accordion, Button, Checkbox, Input, InputGroup, InputNumber, Modal, Pagination, Placeholder, SelectPicker, Stack } from "rsuite";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import SearchIcon from '@rsuite/icons/Search';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import { CreateTicketsFetch, GetTicketsFileFetch, GetTicketsV2Fetch } from "../../connector/connectorV2";
import { event_types, ticket_type } from "./constants";
import { adminConnector } from "../../connector/connector";

export default function TicketsV2(props){
    const [nodes, setNodes] = useState([])
    const [addPop, setAddPop] = useState(false)
    const [removeItem, setRemoveItem] = useState(null)
    const [updateItem, setUpdateItem] = useState(null)
    const [itemToCreate, setItemToCreate] = useState({})

    const [meta, setMeta] = useState(null)

    const [filter, setFilter] = useState(null)
    const [all, setAll] = useState("")

    const [maxPage, setMaxPage] = useState(0)
    const [page, setPage] = useState(0)
    const limit = 100
    const theme = useTheme([
        getTheme(),
        {
          Table: `
            --data-table-library_grid-template-columns:  80px 200px 200px 200px 200px 180px 100px 100px 100px;
          `,
        },
      ]);

    const handelCloseAddPop = ()=>{setAddPop(false);setItemToCreate({})}
    const handleOpenAddPop = ()=>{setAddPop(true)}
    const handleCloseRemovePop = ()=>{setRemoveItem(null)}
    const handleOpenRemovePop = (item) =>{setRemoveItem(item)}
    const handleOpenUpdatePop = (item) =>{setUpdateItem(item)}
    const handleCloseUpdatePop = () => {setUpdateItem(null)}
    
    useEffect(()=>{
        GetItemsAction(page, limit)
    },[])

    const GetItemsAction = (page, limit) => {
        const data = {
            page: page, limit: limit,
            all: all, is_archived: (filter?.is_archived ? filter.is_archived : false), 
            event_type: filter?.event_type, used: filter?.used, is_accepted: filter?.is_accepted
        }
        GetTicketsV2Fetch(data).then(res => {
            setNodes(res.data)
            setMaxPage(res.meta.max_page)
            setMeta(res.meta)
        })
    }

    const GetItemsXLSX = () => {
        const data = {
            page: page, limit: limit,
            all: all, is_archived: (filter?.is_archived ? filter.is_archived : false), 
            event_type: filter?.event_type, used: filter?.used, is_accepted: filter?.is_accepted
        }
        GetTicketsFileFetch(data).then(res => {
            let link = document.createElement('a');
            link.download = 'tickets.xlsx';
            let file = new Blob([res], {type: 'application/json'});
            link.href = URL.createObjectURL(file);
            link.click();
            URL.revokeObjectURL(link.href)
        })
    }

    const UpdateFilter = (newValue, key) => {
        let newFilter = {...filter}
        newFilter[key] = newValue;
        setFilter(newFilter)
    }

    const UpdateItemField = (newValue, key) => {
        let newItem = {...updateItem}
        newItem[key] = newValue;
        setUpdateItem(newItem)
    }

    const UpdateCreateItemField = (newValue, key) => {
        let newItem = {...itemToCreate}
        newItem[key] = newValue;
        setItemToCreate(newItem)
    }

    const CreateAction = () => {
        if (itemToCreate?.type == null) {
            alert("выберите тип мероприятия")
            return
        }
        CreateTicketsFetch(itemToCreate).then(res => {
            handelCloseAddPop()
            setPage(0)
            GetItemsAction(0, limit)
        })
    }

    


    const COLUMNS = [
        { label: 'Id билета', renderCell: (item) => item?.id },
        { label: 'Покупатель', renderCell: (item) => `${item?.owner_name} ${item?.owner_surname}` },
        { label: 'Email', renderCell: (item) => item?.owner_email },
        { label: 'Телефон', renderCell: (item) => item?.owner_phone },
        {
          label: 'Дата',
          renderCell: (item) =>
            item?.date ? new Date(item?.date).toLocaleDateString() : null,
        },
        { label: "Управление", renderCell: (item) => 
            <Stack spacing={5}>
                {!item?.is_archived && 
                <>
                    <Button onClick={(e) => {GetTicketPDF(item.id)}}><FileDownloadIcon /></Button>
                    <Button onClick={(e) => {handleOpenUpdatePop(item)}}><EditIcon/></Button>
                    <Button onClick={(e) => {handleOpenRemovePop(item)}}><TrashIcon /></Button>
                </>
                }
            </Stack>
        },
        {label: "Подтвержден", renderCell: (item) => (item?.is_accepted ? <div className="round green"></div> : <div className="round red"></div>)},
        {label: "Использован", renderCell: (item) => (item?.used ? <div className="round green"></div> : <div className="round red"></div>)},
        {label: "Активен", renderCell: (item) => (item?.is_archived ? <div className="round red"></div> : <div className="round green"></div>)},
      ];
      const data = { nodes };
      
    //additional functions (legacy)
    const AcceptTicketAction = (id) => {
        adminConnector.connect_async("accept_ticket", {id: id})
        .then(res => {
            GetItemsAction(page, limit)
            handleCloseUpdatePop()
        })
    }
    const GetTicketPDF = (id) => {
        adminConnector.get_gile("getTicket_pdf", {id: id})
        .then(res => {
            let link = document.createElement('a');
            link.download = 'ticket.pdf';
            let file = new Blob([res], {type: 'application/json'});
            link.href = URL.createObjectURL(file);
            link.click();
            URL.revokeObjectURL(link.href)
        })
    }
    
    const ArchiveTicket = (uid) => {
        adminConnector.connect_async("send_ticket_to_archive", {uid: uid})
        .then(res => {
            GetItemsAction(page, limit)
            handleCloseRemovePop()
        })
    }
    return (
        <div className="new_page_wrapper">
            <Stack spacing={15} alignItems="center"><div className="main_title">Билеты</div><Button color="blue" appearance="primary" onClick={handleOpenAddPop}><AddOutlineIcon/></Button><Button color="blue" appearance="primary"><FileDownloadIcon /></Button></Stack>
            <Stack spacing={20} wrap={true}>
                <Stack spacing={5}><div>тип мероприятия:</div><SelectPicker data={event_types} value={filter?.event_type} onChange={(val) => {UpdateFilter(val, "event_type")}} /></Stack>
                <Stack spacing={5}><div>показывать архивные: </div><Checkbox checked={filter?.is_archived} onChange={(val, boolVal) => UpdateFilter(boolVal, "is_archived")} /></Stack>
                <Stack spacing={5}><div>тип билета:</div><SelectPicker data={ticket_type} value={filter?.is_accepted} onChange={(val) => {UpdateFilter(val, "is_accepted")}} /></Stack>
                <Stack spacing={5}><Button color="blue" appearance="primary" onClick={(e) => {setPage(0); GetItemsAction(0, limit)}}><SearchIcon /></Button></Stack>
                <Stack spacing={5}><Button color="blue" appearance="primary" onClick={GetItemsXLSX}><FileDownloadIcon /></Button></Stack>
            </Stack>
            <InputGroup>
                <Input value={all} onChange={(val) => setAll(val)} placeholder="общий поиск" />
                <InputGroup.Addon>
                    <SearchIcon onClick={(e) => {setPage(0); GetItemsAction(0, limit)}} />
                </InputGroup.Addon>
            </InputGroup>
            <Accordion>
                <Accordion.Panel header="Аналитика">
                    <Stack spacing={15}>
                        <div>Кол-во (по фильтру): <span className="bold blue">{meta?.count}</span></div>
                        <div>Кол-во: <span className="bold blue">{meta?.main_count}</span></div>
                        <div>Подтвержденные: <span className="bold blue">{meta?.count_is_accepted}</span></div>
                        <div>Не подтвержденные: <span className="bold blue">{meta?.count_is_accepted && meta?.main_count ? meta.main_count - meta.count_is_accepted : null}</span></div>
                        <div>Использованные: <span className="bold blue">{meta?.count_is_used}</span></div>
                        <div>В архиве: <span className="bold blue">{meta?.count_is_archived}</span></div>
                    </Stack>
                </Accordion.Panel>
                
            </Accordion>
            <Pagination total={maxPage*limit +1} limit={limit} activePage={page+1} onChangePage={(page)=>{GetItemsAction(page-1, limit); setPage(page-1)}} />
            <CompactTable columns={COLUMNS} data={data} theme={theme} layout={{ custom: true, horizontalScroll: true }} />
            



            {/* add pop */}
            <Modal open={addPop} onClose={handelCloseAddPop}>
                <Modal.Header>
                <Modal.Title>Добавить билет</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack spacing={10} direction="column" alignItems="flex-start">
                        <Stack spacing={5}><div className="w-25">Имя покупателя:</div> <Input value={itemToCreate?.owner_name} onChange={(val) => {UpdateCreateItemField(val, "owner_name")}} placeholder="имя" /></Stack>
                        <Stack spacing={5}><div className="w-25">Фамилия покупателя:</div> <Input value={itemToCreate?.owner_surname} onChange={(val) => {UpdateCreateItemField(val, "owner_surname")}} placeholder="фамилия" /></Stack>
                        <Stack spacing={5}><div className="w-25">Email покупателя:</div> <Input value={itemToCreate?.owner_email} onChange={(val) => {UpdateCreateItemField(val, "owner_email")}} placeholder="email" /></Stack>
                        <Stack spacing={5}><div className="w-25">Телефон покупателя:</div> <Input value={itemToCreate?.owner_phone} onChange={(val) => {UpdateCreateItemField(val, "owner_phone")}} placeholder="телефон" /></Stack>
                        <Stack spacing={5}><div className="w-25">ID партнера:</div> <Input value={itemToCreate?.owner_login} onChange={(val) => {UpdateCreateItemField(val, "owner_login")}} placeholder="парнерский ID" /></Stack>
                        <Stack spacing={5}><div className="w-25">Номер билета:</div> <InputNumber value={itemToCreate?.num} onChange={(val) => {UpdateCreateItemField(val, "num")}} placeholder="номер билета" /></Stack>
                        <Stack spacing={5}><div className="w-25">Мероприятие:</div> <SelectPicker data={event_types} value={itemToCreate?.type} onChange={(val) => {UpdateCreateItemField(val, "type")}} placeholder="тип мероприятия" /></Stack>
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={CreateAction} appearance="primary">
                    Добавить
                </Button>
                <Button onClick={handelCloseAddPop} appearance="subtle">
                    Отмена
                </Button>
                </Modal.Footer>
            </Modal>

            {/* remove pop */}
            <Modal open={removeItem!=null} onClose={handleCloseRemovePop}>
                <Modal.Header>
                <Modal.Title>Удалить пользователя</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Вы действительно хотите это сделать?
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={(e)=>{ArchiveTicket(removeItem.uid)}} appearance="primary">
                    Удалить
                </Button>
                <Button onClick={handleCloseRemovePop} appearance="subtle">
                    Отмена
                </Button>
                </Modal.Footer>
            </Modal>

            {/* update pop */}
            <Modal open={updateItem!=null} onClose={handleCloseUpdatePop}>
                <Modal.Header>
                <Modal.Title>Изменить пользователя</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack spacing={10} direction="column" alignItems="flex-start">
                    <Stack spacing={5}><div className="w-25">Имя покупателя:</div> <Input value={updateItem?.owner_name}  placeholder="имя" /></Stack>
                        <Stack spacing={5}><div className="w-25">Фамилия покупателя:</div> <Input value={updateItem?.owner_surname}  placeholder="фамилия" /></Stack>
                        <Stack spacing={5}><div className="w-25">Email покупателя:</div> <Input value={updateItem?.owner_email}  placeholder="email" /></Stack>
                        <Stack spacing={5}><div className="w-25">Телефон покупателя:</div> <Input value={updateItem?.owner_phone}  placeholder="телефон" /></Stack>
                        <Stack spacing={5}><div className="w-25">ID партнера:</div> <Input value={updateItem?.owner_login}  placeholder="парнерский ID" /></Stack>
                        <Stack spacing={5}><div className="w-25">Номер билета:</div> <InputNumber value={updateItem?.num}  placeholder="номер билета" /></Stack>
                        <Stack spacing={5}><div className="w-25">Мероприятие:</div> <SelectPicker data={event_types} value={updateItem?.type}  placeholder="тип мероприятия" /></Stack>
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                {!updateItem?.is_accepted &&
                <Button onClick={(e) => {AcceptTicketAction(updateItem.id)}} appearance="primary">
                    Подтвердить
                </Button>}
                <Button onClick={handleCloseUpdatePop} appearance="subtle">
                    Отмена
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}