import { partnerReducer } from "./partnerReducer";
import { adminReducer } from "./adminReducer"
import { mainReducer } from "./mainReducer";
import { combineReducers } from 'redux';
import { newDepReducer } from "./newDepReducer";
export const rootReducer = combineReducers({
    partner: partnerReducer,
    admin: adminReducer,
    main: mainReducer,
    newDep: newDepReducer
})