import React from "react";
import { Outlet } from "react-router";
import Header from "../atoms/header/header";
import { classic_admin_access, navigationItems, navigationItemsSupreme, supreme_access } from "./constants";
import AdminHeader from "../atoms/AdminHeader/AdminHeader";

export default function AdminPanel(props){
    const access_dict = (localStorage.getItem("user_type") == "superadmin" ? supreme_access : classic_admin_access);
    return(
    <>
        <AdminHeader access_dict={access_dict} />
        <Outlet />
    </>)
}