const initialState = {
    reload: false
}

export function mainReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_RELOAD':
            return { ...state, reload: action.payload };
        default:
            return state;
    }
}