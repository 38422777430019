import React, { useEffect, useState } from "react";
import { Modal, Pagination } from "rsuite";
import { GetPartnerTicketsFetch, GetTicketPdfFetch } from "../../connector/connectorV2";
import "./partner_tickets.css"
const event_types = {
    kongres2024_guest: "Конгресс 2024 (Гости)",
    kongres2024_partner: "Конгресс 2024 (Партнеры)",
    event1: "Мероприятие 1",
    event2: "Кик офф 2024",
    summer_moscow: "Лето москва",
    summer_chelyaba: "Лето челябинск",
    summer_irkut: "Лето иркутск",
    simmer_krasno: "Лето краснодар",
    non_type: "Без типа",
}

export const PartnerTickets = (props) => {
    const [tickets, setTickets] = useState([])
    const [page, setPage] = useState(0)
    const [max_page, set_max_page] = useState(0)
    const limit = 50
    const GetTicketsAction = (my_page = page) => {
        GetPartnerTicketsFetch({page: my_page, limit: limit}).then(res => {
            setTickets(res.data);
            set_max_page(res?.meta?.count_page)
        })
    }
    const GetQrAction = (ticket_id) => {
        GetTicketPdfFetch(ticket_id).then(res => {
            let link = document.createElement('a');
            link.download = 'ticket.pdf';
            let file = new Blob([res], {type: 'application/json'});
            link.href = URL.createObjectURL(file);
            link.click();
            URL.revokeObjectURL(link.href)
        })
    }


    useEffect(() => {
        GetTicketsAction()
    }, [])
    return (
        <div className="partner_page_container">
            <div className="simple_text">Билеты</div>
            <div className="partner_table scroll_stylle">
                <div className="partner_tickets_tr partner_tickets_tr_header">
                    <div className="partner_tickets_td">Название</div>
                    <div className="partner_tickets_td">Дата покупки</div>
                    <div className="partner_tickets_td">ФИО</div>
                    <div className="partner_tickets_td">Номер</div>
                    <div className="partner_tickets_td">Оплата подтверждена</div>
                    <div className="partner_tickets_td">Использован</div>
                    <div className="partner_tickets_td"></div>
                </div>
                <div className="partner_tickets_tr">
                    <div className="partner_tickets_td">{event_types["event2"]}</div>
                    <div className="partner_tickets_td">22.02.2022</div>
                    <div className="partner_tickets_td">Александр Александрович Александров</div>
                    <div className="partner_tickets_td">-</div>
                    <div className="partner_tickets_td"><div className="round green"></div></div>
                    <div className="partner_tickets_td"><div className="round green"></div></div>
                    <div className="partner_ticket_modal_open partner_tickets_td">скачать</div>
                </div>
                {tickets?.map((val, i) => 
                    <div className="partner_tickets_tr" key={`partner-ticket-item-${i}`}>
                        <div className="partner_tickets_td">{event_types[val?.type]}</div>
                        <div className="partner_tickets_td">{new Date(val?.date).toLocaleDateString()}</div>
                        <div className="partner_tickets_td">{val?.owner_name} {val?.owner_surname}</div>
                        <div className="partner_tickets_td">{val?.num}</div>
                        <div className="partner_tickets_td">{val?.is_accepted ? <div className="round green"></div> : <div className="round red"></div>}</div>
                        <div className="partner_tickets_td">{val?.used ? <div className="round green"></div> : <div className="round red"></div>}</div>
                        <div  onClick={(e) => {GetQrAction(val.id)}} className="partner_tickets_td partner_ticket_modal_open">скачать</div>
                    </div>
                )}
            </div>
            <Pagination total={max_page + 1} limit={limit} activePage={page + 1} onChangePage={(page) => {GetTicketsAction(page-1); setPage(page-1)}} />
        </div>
    )
}