import React, { useEffect, useState } from "react";
import penSVG from "./../../../source/svg/pen.svg"
import { connect } from 'react-redux';
import HardInput from "../../atoms/hardInput/hardInput";
import { superAdminConnector } from "../../connector/connector";
import "./settings.css";

function Settings(props){
    const [changed, setChanged] = useState(false)

    useEffect(() => {
        getSettings()
    }, [])
    const getSettings = () => {
        superAdminConnector.connect_async("getSettings")
        .then(res => {
            props.set_address(res.data.sender_address);
            props.set_coords(res.data.sender_coords);
            props.set_code(res.data.sender_postal_code);
            props.set_length(res.data.length);
            props.set_width(res.data.width);
            props.set_height(res.data.height);
            props.set_weight(res.data.weight);
            props.set_count(res.data.count);
            props.set_sender_name(res.data.sender_name);
            props.set_sender_surname(res.data.sender_surname);
            props.set_props_value(res.data.props_value);
            props.set_marker_type(res.data.marker_type);
            props.set_city(res.data.sender_city);
            props.set_standart_article(res.data.standart_article)
            props.set_standart_item_name(res.data.standart_item_name)
            props.set_standart_item_price(res.data.standart_item_price)

        })
    }
    const saveSettings = () => {
        
        let dictToSend = {
            sender_address: props.sender_address ? props.sender_address : "",
            sender_coords: props.sender_coords ? props.sender_coords : "",
            sender_postal_code: props.sender_postal_code ? props.sender_postal_code : "",
            length: props.length ? props.length : "",
            width: props.width ? props.width : "",
            height: props.height ? props.height : "",
            weight: props.weight ? props.weight : "",
            count: props.count ? parseInt(props.count) : "",
            sender_name: props.sender_name ? props.sender_name : "",
            sender_surname: props.sender_surname ? props.sender_surname : "",
            props_value: props.props_value ? props.props_value : "",
            marker_type: props.marker_type ? props.marker_type : "",
            sender_city: props.sender_city ? props.sender_city : "",
            standart_article: props.standart_article ? props.standart_article : "",
            standart_item_name: props.standart_item_name ? props.standart_item_name : "",
            standart_item_price: props.standart_item_price ? props.standart_item_price : "",
        }
        superAdminConnector.connect_async("updateSettings", dictToSend)
        .then(res => {

        })
    }
    return(
    <>
        <div className="simple_header mb-40 mt-50">Настройки</div>
        <div className="adminSet_inputsContainer">
            <div className="adminSet_row">
                <span className="blue">Временной интервал: </span> с 9 до 18 <img src={penSVG} />
            </div>
            <div className="column-3 mt-15">
                <HardInput title="Город" className="mt-15" value={props.sender_city} changeAction={props.set_city} />
                <HardInput title="Адрес отправки" className="mt-15" value={props.sender_address} changeAction={props.set_address} />
                <HardInput title="Индекс" className="mt-15" value={props.sender_postal_code} changeAction={props.set_code} />
            </div>
            
            <div className="column-5 mt-15">
                <HardInput title="Длина, см" className="mt-15" value={props.length} changeAction={props.set_length} />
                <HardInput title="Ширина, см" className="mt-15" value={props.width} changeAction={props.set_width} />
                <HardInput title="Высота, см" className="mt-15" value={props.height} changeAction={props.set_height} />
                <HardInput title="Вес, г" className="mt-15" value={props.weight} changeAction={props.set_weight} />
                <HardInput title="Кол-во ед, шт" className="mt-15" value={props.count} changeAction={props.set_count} />
            </div>

            <div className="column-3 mt-15">
                <HardInput title="Артикль" className="mt-15" value={props.standart_article} changeAction={props.set_standart_article} />
                <HardInput title="Имя товара" className="mt-15" value={props.standart_item_name} changeAction={props.set_standart_item_name} />
                <HardInput title="Цена товара" className="mt-15" value={props.standart_item_price} changeAction={props.set_standart_item_price} />
            </div>

            <div className="column-2 mt-15">
                <HardInput title="Фамилия контактного лица" className="mt-15" value={props.sender_surname} changeAction={props.set_sender_surname} />
                <HardInput title="Имя контактного лица" className="mt-15" value={props.sender_name} changeAction={props.set_sender_name} />
            </div>
            <div className="column-2 mt-15">
                <HardInput title="Значение реквизита" className="mt-15" value={props.props_value} changeAction={props.set_props_value} />
                <HardInput title="Тип маркеровки" className="mt-15" value={props.marker_type} changeAction={props.set_marker_type} />
            </div>
            <div className="simple_btn mt-15" onClick={(e) => {saveSettings()}}>Подтвердить</div>
        </div>
    </>
    )
}


const mapStateToProps = (store) => {
    return {
        sender_address: store.admin.sender_address,
        sender_city: store.admin.sender_city,
        sender_coords: store.admin.sender_coords,
        sender_postal_code: store.admin.sender_postal_code,
        length: store.admin.length,
        width: store.admin.width,
        height: store.admin.height,
        weight: store.admin.weight,
        count: store.admin.count,
        sender_name: store.admin.sender_name,
        sender_surname: store.admin.sender_surname,
        props_value: store.admin.props_value,
        marker_type: store.admin.marker_type,
        standart_article: store.admin.standart_article,
        standart_item_name: store.admin.standart_item_name,
        standart_item_price: store.admin.standart_item_price,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        set_address: (params) => dispatch({type: "SET_ADDRESS", payload: params}),
        set_city: (params) => dispatch({type: "SET_SENDER_CITY", payload: params}),
        set_coords: (params) => dispatch({type: "SET_COORDS", payload: params}),
        set_code: (params) => dispatch({type: "SET_POSTAL_CODE", payload: params}),
        set_length: (params) => dispatch({type: "SET_LENGTH", payload: params}),
        set_width: (params) => dispatch({type: "SET_WIDTH", payload: params}),
        set_height: (params) => dispatch({type: "SET_HEIGHT", payload: params}),
        set_weight: (params) => dispatch({type: "SET_WEIGHT", payload: params}),
        set_count: (params) => dispatch({type: "SET_COUNT", payload: params}),
        set_sender_name: (params) => dispatch({type: "SET_SENDER_NAME", payload: params}),
        set_sender_surname: (params) => dispatch({type: "SET_SENDER_SURNAME", payload: params}),
        set_props_value: (params) => dispatch({type: "SET_PROPS_VALUE", payload: params}),
        set_marker_type: (params) => dispatch({type: "SET_MARKER_TYPE", payload: params}),
        set_standart_article: (params) => dispatch({type: "SET_STANDART_ARTICLE", payload: params}),
        set_standart_item_name: (params) => dispatch({type: "SET_STANDART_ITEM_NAME", payload: params}),
        set_standart_item_price: (params) => dispatch({type: "SET_STANDART_ITEM_PRICE", payload: params}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);