import React, { useRef, useEffect, useState } from "react";
import { YMaps, Map, Placemark, useYMaps } from '@pbe/react-yandex-maps';
import { geocoder } from "../../connector/geocoder";
import "./popUp.css";
import exitSVG from "./../../../source/svg/exit.svg";
import axios from "axios";
export default function PopUp(props){
    return (
        <>
        {props.visible && 
        <div className="popUpContainer">
            <div className="popUp">
                <div className="exitRow"><img onClick={(e)=>{props.exit()}} src={exitSVG} /></div>
                <div className="popText">{props.text}</div>
                <div className="exitRow"><div className="simple_btn" onClick={(e)=> {props.action()}}>{props.btn_text}</div></div>
            </div>
            <div className="greyBack"></div>
        </div>}
        </>
    )
}

export function EditPopUp(props){
    return (
        <>
        {props.visible &&
        <div className="popUpContainer">
            <div className="popUp editPopUp">
                <div className="exitRow"><img onClick={(e)=>{props.exit()}} src={exitSVG} /></div>
                <div className="center_row mb-40"><div className="simple_header">{props.header}</div></div>
                <div className="editPopContainer">{props.fields}</div>
                <div className="simple_btn" onClick={(e)=> {props.action()}}>{props.btn_text}</div>
                {props.additional}
            </div>
            <div className="greyBack"></div>
        </div>
        }
        </>
    )
}


export function AddressPopUp(props){
    const [ymap, setYmap] = useState(null)
    const [varList, setVarList] = useState([])
    const [varListOpen, setVarListOpen] = useState(false);
    const [address, setAddress] = useState(props.address)
    const [city, setCity] = useState(props.city);
    const [postal_code, setPostalCode] = useState(props.postal_code)
    const [inpAddress, setInAddress] = useState(props.address && props.city ? `${props.address}, ${props.city}` : "")
    const [coords, setCoords] = useState([55.75, 37.57])
    
    useState(() => {
        console.log(props.postal_code)
        if (props.address && props.city){
        }
    }, [])

    const getAddress = (coords) => {
        setCoords(coords)
        props.setCoords(coords)
        geocoder.getAddress(coords)
        .then(res => {
            setPostalCode(res.address.postcode)
            setCity(res.address.city)
            setAddress(res.display_name);
            setInAddress(res.display_name)
        })
    }

    const getCoords = (addressVar) => {
        setPostalCode(null)
    }
    const getAddressVars = (addr = inpAddress) => {
        getCoords(addr)
    }
    const action = () => {
        props.action(postal_code, address, city, coords)
    }
    let mapState = { center: coords, zoom: 15 }
    const changeCurAddress = (value) => {
        setAddress(value.display_name)
        setInAddress(value.display_name)
        setCoords([value.lat, value.lon])
        props.setCoords([value.lat, value.lon])
        setVarListOpen(false)
    }
    let addVarsInp = varList.map(value => 
        <div className="drop-var-item simple_scroll" onClick={e => {changeCurAddress(value)}}>{value.display_name}</div>
    )
    return(
        <>
        {props.visible &&
        <div className="popUpContainer">
            <div className="popUp editPopUp">
                <div className="exitRow"><img onClick={(e)=>{props.exit()}} src={exitSVG} /></div>
                <div className="left_row mb-15"><div className="simple_header">{props.header}</div></div>
                <div className="mb-15 addressString">
                    Выбранный адрес: 
                    <input className="popup_input" placeholder="Начните вводить адрес" onChange={(e) => {setInAddress(e.target.value)}}  value={inpAddress} />
                    <div className="simple_btn" onClick={e => {getAddressVars()}}>найти</div>
                    {varListOpen && 
                    <div className="drop-vars-container simple_scroll">
                        {addVarsInp}
                    </div>}
                </div>
                <div className="mapContainer">
                <YMaps query={{ load: "package.full", apikey: "myApi" }}>
                    <Map width="100%" height="100%" onLoad={(ymaps) => setYmap(ymaps)} state={mapState} onClick={(e) => {getAddress(e.get("coords"))}}>
                        <Placemark geometry={coords} />
                    </Map>
                </YMaps>
                </div>
                <div className={"simple_btn mt-15" + (postal_code ? "" : " no_pointer simple_btn_grey")} onClick={(e)=> {if (postal_code) action()}}>{props.btn_text}</div>
            </div>
            <div className="greyBack"></div>
        </div>
        }
        </>
    )
}

export function PickAddressPopUp(props){
    const pickAction = (pickedValue) => {
        if (props.action){
            props.action(pickedValue)
        }
    }
    let mapState = { center: props.coords, zoom: 15 }
    let placemarks = props.items.map(value => <Placemark geometry={value.coords} onClick={(e) => {pickAction(value)}} />)
    return (
        <>
        {props.visible &&
        <div className="popUpContainer">
            <div className="popUp editPopUp">
                {props.loading && <div className="sceleton"></div>}
                <div className="exitRow"><img onClick={(e)=>{props.exit()}} src={exitSVG} /></div>
                <div className="center_row mb-15"><div className="simple_header">{props.header}</div></div>
                <div className="mapContainer">
                    <YMaps>
                        <Map width="100%" height="100%" state={mapState}>
                            {placemarks}
                        </Map>
                    </YMaps>
                </div>
            </div>
            <div className="greyBack"></div>
        </div>
        }
        </>
    )
}