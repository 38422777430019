import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
import { mainDispatcher } from "../../../store/actions/mainActions";
import { mainMapper } from "../../../store/mappers/mainMapper";
import penSVG from "./../../../source/svg/pen.svg"
export default function InputT(props){
    const [mainState, setMainState] = useState("")
    const inpRef = useRef()
    useEffect(() => {
        if (!props.update) inpRef.current.value = props.value;
    }, [props.value])
    const updateInput = (e) => {
        setMainState(e.target.value)
        if (props.update){
            props.update(e.target.value)
        }
    }
    return(
            <div className="newDepSetItem">
                <span className="blueText">{props.name}:  </span>
                <div className="inputTrow">
                    <input className="invisible_input" 
                        disabled={ props.active_input_new_dep == props.id ? false : true} 
                        id={props.id} 
                        ref={inpRef}
                        value={(props.update ? props.value : mainState )}
                        onChange={updateInput}
                    /> 
                    {props.active_input_new_dep != props.id && <img src={penSVG} onClick={(e) => {props.set_cur_input(props.id)}} />}
                </div>
                
            </div>
    )
}
