import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';

const UploaderPM = (props) => {
    const [base64Files, setBase64File] = useState([])

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
    const UploadFileAction = (file) => {
        toBase64(file).then(res => {
            let new_base_files = [...base64Files]
            new_base_files.push(res)
            setBase64File(new_base_files)
            if (props.UploadBaseFileAction) {props.UploadBaseFileAction(new_base_files)}
        });
    }
    const RemoveFile = (base_file) => {
        let new_base_files = [...base64Files]
        new_base_files = new_base_files.filter(val => val != base_file)
        setBase64File(new_base_files)
        if (props.UploadBaseFileAction) {props.UploadBaseFileAction(new_base_files)}
    }
    return (
        <div className={"uploader_input_wrapper " + props.className}>
            <div className="uploader_input_title">{props.title}</div>
            {base64Files != null && base64Files.length != 0 &&
                <div className="success_img_uploader_wrapper">
                    {base64Files.map(val => <div className="uploader_img_wrapper"><img className="uploader_img" src={val} /> <CloseIcon className="uploader_img_axit" onClick={(e) => {RemoveFile(val)}} /></div>)}
                </div>
            }
            <FileUploader handleChange={UploadFileAction} types={props.fileTypes}>
                {base64Files == null || base64Files.length == 0 &&
                    <div className="uploader_area_wrpapper">
                        <div className="uploader_area_text">Нажмите или Перетащите файл в дайнную область для загрузки</div>
                    </div>
                }
                {base64Files != null && base64Files.length != 0 &&
                <div className="success_uploader_wrapper">
                    
                    
                    <div className="uploader_area_wrpapper uploader_area_wrpapper_success">
                        <CheckOutlineIcon className="uplaoder_success_image" />
                        <div className="uploader_area_text">Файл успешно загружен! Нажимите или Перетащите чтобы загрузить еще</div>
                        
                    </div>
                    
                </div>
                    
                }
            </FileUploader>
        </div>
        
    )
}

export default UploaderPM;