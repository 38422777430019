import React, { useState, useRef, useEffect } from "react";
import Checkbox from "../../atoms/checkbox/checkbox";
import penSVG from "./../../../source/svg/pen.svg";
import { EditPopUp } from "../../atoms/popUp/popUp";
import { adminConnector } from "../../connector/connector";
import "./partners.css";
import testFile from "./test.csv"
import { Button, Input, Modal, Stack } from "rsuite";
import { AcceptPartnerFetch } from "../../connector/connectorV2";

const adminsTest = [{id: 1, name: "Николай Федорович", login: "iva228", email: "iva@mail.ru"},{id: 2, name: "Иван Иванов", login: "test2222", email: "test@tt.com"}];


export default function Partners(props){

    const [list, setList] = useState(adminsTest)

    const [curentItem, setCurentItem] = useState(null);
    const [editItemPop, setEditItemPop] = useState(false);
    const [checkedAdmins, setCheckedAdmins] = useState([]);
    const [addAdminPop, setAddAdminPop] = useState(false);
    const [fileLabel, setFileLabel] = useState("загрузить csv")
    const [file, setFile] = useState(null)

    const idRefAdd = useRef(null);
    const passRefAdd = useRef(null);
    const surnameRefAdd = useRef(null);
    const nameRefAdd = useRef(null);

    const passRef = useRef(null);
    const mailRef = useRef(null);
    const nameRef = useRef(null);

    useEffect(() => {
        getPartnerList()
    }, [])

    const getPartnerList = () => {
        adminConnector.connect_async("getPartners")
        .then(res => {
            setList(res.data)
        })
    }

    const checkAll = (ch) => {
        if (ch) {
            let newChecked = [];
            list.map(value => {newChecked.push(value.id)})
            setCheckedAdmins(newChecked);
        } else {
            setCheckedAdmins([])
        }
    }

    const pushCheck = (id, ch) => {
        if (ch){
            if (checkedAdmins.includes(id)) return;
            setCheckedAdmins([...checkedAdmins, id]);
        } else {
            let newChecked = checkedAdmins.filter(value => value != id)
            setCheckedAdmins(newChecked);
        }
        
    }

    const addAdmin = () => {
        let partnerData = {
            name: nameRefAdd.current.value,
            surname: surnameRefAdd.current.value,
            password: passRefAdd.current.value,
            partnerId: idRefAdd.current.value
        }
        adminConnector.connect_async("addPartners", {list: [partnerData]})
        .then(res => {
            setAddAdminPop(false)
            getPartnerList()
        })

    }

    const openEditor = (curAdmin) => {
        setCurentItem(curAdmin);
        /* setEditItemPop(true); */
    }

    const edit = () => {
        let newMail = mailRef.current.value;
        let newName = nameRef.current.value;
        let newPass = null;
        if (passRef.current.value && !passRef.current.value.includes("*")) newPass = passRef.current.value
    }

    const closeEditor = () => {
        setEditItemPop(false);
    }

    const deletePartners = () => {
        adminConnector.connect_async("removePartners", {list: checkedAdmins})
        .then(res => {
            setCheckedAdmins([])
            getPartnerList();
        })
    }

    const checkFile = (e) => {
        var input = e.target;
        var url = e.target.value;
        var ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
        if (!input.files || !input.files[0] || !(ext == "csv")){
            setFileLabel("Не корректный формат")
            return
        }
        var formdata = new FormData();
        formdata.append("file_bytes", input.files[0]);

        adminConnector.connect_async("countPartnersFromFile", formdata)
        .then(res => {
            setFileLabel(`${res.count} шт.`)
            setFile(input.files[0])
        }, err => {
            setFileLabel("Не корректный файл")
        })
        
    }
    const loadFile = () => {
        if (!file) return
        var formdata = new FormData();
        formdata.append("file_bytes", file);
        adminConnector.connect_async("createPartnersFromFile", formdata)
        .then(res => {
            setEditItemPop(false)
            getPartnerList()
        }, err => {
            alert("что то пошло не так")
            setEditItemPop(false)
            getPartnerList()
        })
    }

    let counter = 0;
    
    const AcceptPartner = () => {
        AcceptPartnerFetch(curentItem.id).then(res => {
            getPartnerList()
            setCurentItem(null)
        })
    }
    
    let editFileds = 
    <>
    </>
    let addFields = 
    <>
        <div className="editPopRow">
            <input className="simple_input" ref={nameRefAdd} type="text" placeholder="Имя" />
            <input className="simple_input" ref={surnameRefAdd} type="text" placeholder="Фамилия" />
        </div>
        <div className="editPopRow">
            <input className="simple_input" ref={idRefAdd} type="text" placeholder="Id партнера" />
            <input className="simple_input" ref={passRefAdd} type="password" placeholder="Пароль" />
        </div>
    </>
    let btn_for_document = <div className="additional_btn_container">
                                <label for="file-upload" class="custom-file-upload" className="simple_file">{fileLabel}</label>
                                <input onChange={checkFile} id="file-upload" type="file" /> 
                                {file && <div className={"simple_btn" + (file ? "" : " simple_btn_grey no_pointer")} onClick={loadFile}>загрузить из файла</div>}
                                <div className="simple_note">* Файл формата csv. <a href={testFile}>Скачать</a> пример файла.</div>
                            </div>
    let rows = list.map(value => {
        ++counter;
        return (
        <div className={"admins-table-tr partners-table-list " + (counter % 2 == 0 ? "admins-table-second" : "")}>
            <div><Checkbox checked={checkedAdmins.includes(value.id)} action={(ch) => {pushCheck(value.id, ch)}} /></div>
            <div>{value.partnerId}</div>
            <div>{value.name} {value.surname}</div>
            <div>{value.email ? value.email : "-" }</div>
            <div>{(value?.is_register ? <div className="round green"></div> : <div className="round red"></div>)}</div>
            <div><img className="cursorPointer" src={penSVG} onClick={(e) => {openEditor(value)}} /></div>
        </div>)}
        )
    return (
    <div className="adminsListContainer">
        <div className="between_row">
            <div className="simple_header">Партнеры</div>
            {/* <div className="simple_btn simple_btn_small" onClick={(e) => {setAddAdminPop(true)}}>+ Добавить парнера</div> */}
        </div>
        <div className="admins-table">
            <div className="admins-table-tr partners-table-list admins-table-head">
                <div><Checkbox action={(ch) => {checkAll(ch)}} /></div>
                <div>Id партнера</div>
                <div>Имя Фамилия</div>
                <div>Электронная почта</div>
                <div>Подтвержден</div>
                <div><img src={penSVG} /></div>
            </div>
            {rows}
            {checkedAdmins.length != 0 &&
            <div className="admin-table-btnsContainer">
                <div className="simple_btn simple_btn_grey" onClick={(e) => {setCheckedAdmins([])}}>Отменить</div>
                <div className="simple_btn" onClick={(e) => {deletePartners()}}>Удалить</div>
            </div>}
        </div>
        {editItemPop && 
            <EditPopUp fields={editFileds} btn_text="Сохранить данные" header="Данные парнера" action={edit} exit={closeEditor} visible={editItemPop} />}
        {addAdminPop && 
            <EditPopUp additional={btn_for_document} fields={addFields} btn_text="Сохранить" header="Добавить парнера" action={addAdmin} exit={() => {setAddAdminPop(false)}} visible={addAdminPop} />}
        
        
        <Modal open={curentItem != null} onClose={()=>{setCurentItem(null)}}>
            <Modal.Header>
                <Modal.Title>Информация о парнере</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack spacing={10} direction="column" alignItems="flex-start">
                    <Stack spacing={5}><div className="w-25">Id парнера:</div> <Input value={curentItem?.partnerId} className="w-100" type="text" placeholder="Id парнера"/></Stack>
                    <Stack spacing={5}><div className="w-25">Имя:</div> <Input value={curentItem?.name} className="w-100" type="text" placeholder="Имя"/></Stack>
                    <Stack spacing={5}><div className="w-25">Фамилия:</div> <Input value={curentItem?.surname} className="w-100" type="text" placeholder="Фамилия"/></Stack>
                    <Stack spacing={5}><div className="w-25">Телефон:</div> <Input value={curentItem?.phone} className="w-100" type="text" placeholder="Телефон"/></Stack>
                    <Stack spacing={5}><div className="w-25">E-mail:</div> <Input value={curentItem?.email} className="w-100" type="text" placeholder="E-mail"/></Stack>
                </Stack>
            </Modal.Body>
            <Modal.Footer>
                {curentItem?.is_register == false &&
                    <Button onClick={AcceptPartner} appearance="primary">
                        Утвердить
                    </Button>}
                {/* <Button onClick={CreateAction} appearance="primary">
                    Сохранить данные
                </Button> */}
            </Modal.Footer>
        </Modal>
    </div>
    )
}