import React from "react";
import logoSVG from './../../../source/svg/logo.svg'

export default function HeaderV2(props){
    return (
        <div className="unpanel_header">
            <div><img src={logoSVG} /></div>
            <div className="simple_small_btn">Войти</div>
        </div>
    )
}