import axios from "axios";

const api_book = {
    "partner": {
        "getSettings": {url: "/api/v1/partner/settings", method: "get"},
        "getPostVariables": {url: "/api/v1/partner/postVariables", method: "post"},
        "addDeparture": {url: "/api/v1/partner/addDeparture", method: "put"},
        "getDepartures": {url: "/api/v1/partner/departures", method: "get"},
        "updateSettings": {url: "/api/v1/partner/updateSettings", method: "put"},
        "changePassword": {url: "/api/v1/partner/changePassword", method: "put"},
        "getPointsHistory": {url: "/api/v1/partner/getPointsHistory", method: "post"},
        "getCurPoints": {url: "/api/v1/partner/getCurPoints", method: "post"},
        "getOffice": {url: "/api/v1/partner/getPostOffice", method: "post"},
        "getCoords": {url: "/api/v1/partner/getAddressCoords", method: "post"},
    },
    "admin": {
        "getDepartures": {url: "/api/v1/admin/getDepartures", method: "post"},
        "editDepartures": {url: "/api/v1/admin/editDepartures", method: "put"},
        "confirmDepartures": {url: "/api/v1/admin/confirmDepartures", method: "post"},
        "removeDepartures": {url: "/api/v1/admin/removeDepartures", method: "delete"},
        "getPartners": {url: "/api/v1/admin/getPartners", method: "post"},
        "addPartners": {url: "/api/v1/admin/addPartners", method: "put"},
        "removePartners": {url: "/api/v1/admin/removePartners", method: "delete"},
        "getReceipts": {url: "/api/v1/admin/getReceipts", method: "post"},
        "getQr": {url: "/api/v1/admin/getQr", method: "post"},
        "countPartnersFromFile": {url: "/api/v1/admin/countPartnersFromFile", method: "post"},
        "createPartnersFromFile": {url: "/api/v1/admin/createPartnersFromFile", method: "post"},
        "getTickets": {url: "/api/v1/admin/getTicketList", method: "post"},
        "getTicket_pdf": {url: "/api/v1/admin/get_ticket", method: "post"},
        "accept_ticket": {url: "/api/v1/admin/accept_ticket", method: "post"},
        "getTickets_xlsx": {url: "/api/v1/admin/get_all_tickets", method: "get"},
        "send_ticket_to_archive": {url: "/api/v1/admin/ticket_archive", method: "post"},
        "new_free_ticket": {url: "/api/v1/admin/new_free_ticket", method: "post"},
    },
    "auth": {
        "init_code": {url: "/api/v1/auth/init-registration-code", method: "post"},
        "accept_code": {url: "/api/v1/auth/accept-registration-code", method: "post"},
        "accept_user": {url: "/api/v1/auth/accept-user-code", method: "post"},
        "is_reg": {url: "/api/v1/auth/is_registered", method: "post"}
    },
    "superadmin": {
        "getAdministration": {url: "/api/v1/admin/getAdministration", method: "post"},
        "createAdministration": {url: "/api/v1/admin/createAdministration", method: "put"},
        "editAdministration": {url: "/api/v1/admin/editAdministration", method: "put"},
        "deleteAdministration": {url: "/api/v1/admin/deleteAdministration", method: "delete"},
        "getSettings": {url: "/api/v1/admin/getSettings", method: "get"},
        "updateSettings": {url: "/api/v1/admin/updateSettings", method: "put"},
    }

}
class Auth{
    constructor(){
        this._url = "https://backend.my-pmi.ru";
        //this._url = "http://127.0.0.1:8080";
        this._login_url = "/api/v1/auth/login";
    }
    login(login, password){
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url: `${this._url}${this._login_url}`,
                data: {login: login, password: password},
            })
            .then(res => {
                localStorage.setItem("access_token", res.data.data.access)
                localStorage.setItem("user_name", res.data.user_name)
                localStorage.setItem("user_surname", res.data.user_surname)
                localStorage.setItem("user_type", res.data.data.user_type)
                resolve(res.data.data.user_type)
            })
            .catch(err => {reject(err)})
        })
    }
    
}
class Connector extends Auth{
    constructor(api_book){
        super()
        this._api_book = api_book;
    }
    connect_async(api, data=null, level=1){
        const token = localStorage.getItem("access_token");
        const url = this._api_book[api].url;
        const method = this._api_book[api].method;
        console.log(data)
        return new Promise((resolve, reject) => {
            axios({
                method: method,
                url: `${this._url}${url}`,
                data: data,

                headers: {
                    "Authorization": `Bearer: ${token}`
                }
            })
            .then(res => { if (level==0) {resolve(res)} else {resolve(res.data)} })
            .catch(err => {reject(err)})
        })
    }
    get_gile(api, data=null, level=1){
        const token = localStorage.getItem("access_token");
        const url = this._api_book[api].url;
        const method = this._api_book[api].method;
        return new Promise((resolve, reject) => {
            axios({
                method: method,
                url: `${this._url}${url}`,
                data: data,
                headers: {
                    "Authorization": `Bearer: ${token}`,
                },
                responseType: 'blob'
            })
            .then(res => { if (level==0) {resolve(res)} else {resolve(res.data)} })
            .catch(err => {reject(err)})
        })
    }
}



export const partnerConnector = new Connector(api_book["partner"]);
export const adminConnector = new Connector(api_book["admin"]);
export const superAdminConnector = new Connector(api_book["superadmin"]);
export const authConnector = new Connector(api_book["auth"]);