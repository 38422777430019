const initialState = {
    sender_address: "",
    sender_city: "",
    sender_coords: "",
    sender_postal_code: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    count: "",
    sender_name: "",
    sender_surname: "",
    props_value: "",
    marker_type: "",
    standart_article: "",
    standart_item_name: "",
    standart_item_price: "",
};

export function adminReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_ADDRESS':
            return { ...state, sender_address: action.payload };
        case 'SET_COORDS':
            return { ...state, sender_coords: action.payload };
        case 'SET_POSTAL_CODE':
            return { ...state, sender_postal_code: action.payload };
        case 'SET_LENGTH':
            return { ...state, length: action.payload };
        case 'SET_WIDTH':
            return { ...state, width: action.payload };
        case 'SET_HEIGHT':
            return { ...state, height: action.payload };
        case 'SET_WEIGHT':
            return { ...state, weight: action.payload };
        case 'SET_COUNT':
            return { ...state, count: action.payload };
        case 'SET_SENDER_NAME':
            return { ...state, sender_name: action.payload };
        case 'SET_SENDER_SURNAME':
            return { ...state, sender_surname: action.payload };
        case 'SET_PROPS_VALUE':
            return { ...state, props_value: action.payload };
        case 'SET_MARKER_TYPE':
            return { ...state, marker_type: action.payload };
        case 'SET_SENDER_CITY':
            return { ...state, sender_city: action.payload };
        case 'SET_STANDART_ARTICLE':
            return { ...state, standart_article: action.payload };
        case 'SET_STANDART_ITEM_NAME':
            return { ...state, standart_item_name: action.payload };
        case 'SET_STANDART_ITEM_PRICE':
            return { ...state, standart_item_price: action.payload };

        default:
            return state;
    }
}