const initialState = {
    page: "invite",
    city: "",
    street: "",
    index: "",
    house: "",
    flat: "",
    active_input_new_dep: null
};

export function partnerReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_PAGE':
            return { ...state, page: action.payload };
        case 'SET_ACTIVE_INPUT_NEW_DEP':
            return { ...state, active_input_new_dep: action.payload };
        default:
            return state;
    }
}