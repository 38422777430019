import React, { useEffect, useState } from "react";
import logoSVG from './../../source/svg/logo.svg'
import HeaderV2 from "../atoms/header/headerV2";
import CheckIcon from '@rsuite/icons/Check';
import { useSearchParams } from "react-router-dom";
import { GetApplicationStatusFetch } from "../connector/connectorV2";
import SendIcon from '@rsuite/icons/Send';
import AdminIcon from '@rsuite/icons/Admin';
import { application_statuses } from "../admin/applications/constants";
import PeoplesUploadedIcon from '@rsuite/icons/PeoplesUploaded';

export default function ApplicationTracking(props){
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState(null)
    const [hash, setHash] = useState(null)
    const [status, setStatus] = useState(null)

    useEffect(() => {
        const application_id = searchParams.get("id")
        const application_hash = searchParams.get("track")
        setId(application_id)
        setHash(application_hash)
        if (application_id == null || application_hash == null){
            return
        } else {
            GetApplicationStatusFetch({id: application_id, application_hash: application_hash}).then(res => {
                setStatus(res.data)
            })
        }
    }, [])
    return (
        <div className="simple_page_wrapper">
            <HeaderV2 />
            <div className="apl_track_page_wrapper">
                <div className="apl_track_title">Ваша заявка №{id}</div>
                <div className="apl_track_title">трек-номер: {hash}</div>
                <div className="apl_track_wrapper">
                    <div className={`apl_track_item apl_track_item_active`}>
                        <div className="apl_track_img_wrapper">
                            <SendIcon />
                        </div>
                        <div className="apl_track_text">Принята</div>
                    </div>
                    <div className={`apl_track_item ${(status == 'working' || status == 'close' || status == "working_hard" || status=="waiting_ans"  ? "apl_track_item_active" : "")}`}>
                        <div className="apl_track_img_wrapper">
                            <AdminIcon />
                        </div>
                        <div className="apl_track_text">На проверке</div>
                    </div>
                    {status == "waiting_ans" &&
                        <div className={`apl_track_item apl_track_item_active`}>
                            <div className="apl_track_img_wrapper">
                                <PeoplesUploadedIcon />
                            </div>
                            <div className="apl_track_text">Ожидает ответа</div>
                        </div>
                    }
                    <div className={`apl_track_item ${(status == 'close' || status == "working_hard" || status == "waiting_accept" ? "apl_track_item_active" : "")}`}>
                        <div className="apl_track_img_wrapper">
                            <CheckIcon />
                        </div>
                        <div className="apl_track_text">Обработка</div>
                    </div>
                    <div className={`apl_track_item ${(status == 'close' ? "apl_track_item_active" : "")}`}>
                        <div className="apl_track_img_wrapper">
                            <CheckIcon />
                        </div>
                        <div className="apl_track_text">Закрыта</div>
                    </div>
                </div>
                <div className="apl_track_title">статус: <span className="blue bold">{(status ? application_statuses.filter(val => val.value == status)[0]?.label : status)}</span></div>
                {status == "waiting_ans" && 
                    <div>Администратор ожидает вашего ответа на указанной в заявке почте</div>
                }
            </div>
            
        </div>
    )
}