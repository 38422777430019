export const application_statuses = [
    {label: "новая", value: "new"},
    {label: "на_проверке", value: "working"},
    {label: "обработка", value: "working_hard"},
    {label: "ожидает ответа", value: "waiting_ans"},
    {label: "подтверждение", value: "waiting_accept"},
    {label: "закрыта", value: "close"},
]
export const application_statuses_not_accepted = [
    {label: "новая", value: "new"},
    {label: "на_проверке", value: "working"},
    
    {label: "обработка", value: "working_hard"},
    {label: "ожидает ответа", value: "waiting_ans"},
    {label: "подтверждение", value: "waiting_accept"},
]
export const yn_data = [
    {label: "да", value: true},
    {label: "нет", value: false},
]
export const aplication_types = [
    {label: "Повреждение (товара)", value: "Повреждение (товара)"},
    {label: "Нехватка", value: "Нехватка"},
    {label: "Перепутали товар", value: "Перепутали товар"},
    {label: "Долгая доставка", value: "Долгая доставка"},
    {label: "Чужой заказ", value: "Чужой заказ"},
    {label: "Срок годности", value: "Срок годности"},
    {label: "Заводской брак", value: "Заводской брак"},
    {label: "Другой вид претензии", value: "Другой вид претензии"},
    {label: "Предложение по улучшению сервиса", value: "Предложение по улучшению сервиса"},
]