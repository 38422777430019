import 'rsuite/dist/rsuite.min.css';
import './source/css/App.css';
import "./source/css/Sceleton.css";
import Invite from './components/invite/invite';
import PartnerPanel from './components/partner/partnerPanel';
import Departures from './components/partner/departures/departures';
import NewDeparture from './components/partner/newDeparture/newDeparture';
import Settings from './components/partner/settings/settings';
import Partners from './components/admin/partners/partners';
import AdminDeps from './components/admin/departures/departures';
import Administrators from './components/admin/administrators/administrators';
import AdminSettings from './components/admin/settings/settings';
import AdminPanel from './components/admin/adminPanel';
import Tickets from './components/admin/tickets/tickets';
import Points from './components/partner/points/points';
import store from './store/store';
import { Provider } from 'react-redux';
import Loader from './components/atoms/loader/loader';
import 'rsuite/dist/rsuite.min.css';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import TicketsV2 from './components/admin/tickets_v2/ticketsV2';
import ApplicationForm from './components/application_form/application_form';
import ApplicationTracking from './components/application_tracking/application_tracking';
import Applications from './components/admin/applications/applications';
import DataPolitics from './components/docs/DataPolitics';
import { ForgetDone } from './components/invite/forget';
import { PartnerTickets } from './components/partner/tickets/partner_tickets';
function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Loader />
        <BrowserRouter>
          <Routes>
            <Route path={"/data-politics"} element={<DataPolitics />} />
            <Route path={"/application"} element={<ApplicationForm />} />
            <Route path={"/application-tracking"} element={<ApplicationTracking />} />
            <Route path={"/"} element={<Invite />} />
            <Route path={"/forget"} element={<ForgetDone />} />
            <Route path={"/partner"} element={<PartnerPanel />} >
              <Route path="/partner/departures" element={<Departures />} />
              <Route path="/partner/tickets" element={<PartnerTickets />} />
              <Route path="/partner/createDeparture" element={<NewDeparture />} />
              <Route path="/partner/settings" element={<Settings />} />
              <Route path="/partner/points" element={<Points />} />
            </Route>
            <Route path='/administrator' element={<AdminPanel />}>
              <Route path='/administrator/partners' element={<Partners />} />
              <Route path='/administrator/departures' element={<AdminDeps />} />
              <Route path='/administrator/administrators' element={<Administrators />} />
              <Route path='/administrator/tickets' element={<TicketsV2 />} />
              <Route path='/administrator/settings' element={<AdminSettings />} />
              <Route path='/administrator/applications' element={<Applications />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
