import React, { useState, useEffect } from "react";
import "./departure.css";
import sortTopSVG from "./../../../source/svg/sortToTop.svg";
import sortBottomSVG from "./../../../source/svg/sortToBottom.svg";
import DropFilter from "../../atoms/dropFilter/dropFilter";
import { partnerConnector } from "../../connector/connector";
import { orderTypesBook } from "./constants";

export default function Departures(props){
    const [deps, setDeds] = useState([]); 
    useEffect(() => {
        getDeps();
    }, [])
    const getDeps = () => {
        partnerConnector.connect_async("getDepartures")
        .then(res => {
            setDeds(res.data);
        })
    }

    let i = 0;
    let items = deps.map(value => 
    <div className={"dep_item" + ((++i)%2 == 0 ? " dep_item_grey" : "")}>
        <div>г. {value.receiving_city}, {value.receiving_address}</div>
        <div>{(new Date(value.order_date)).toLocaleString()}</div>
        <div>{value.receive_date ? (new Date(value.receive_date)).toLocaleString() : "-"}</div>
        <div>{value.status}</div>
        <div>{value.receiver_initials}</div>
        <div>{value.trek ? value.trek : "-"}</div>
        <div>{orderTypesBook[value.type]}</div>
        
    </div>)
    let testStatusItems = [{name: "в пути", value: "inDep"}, {name: "доставлен", value: "deped"}]
    return(
        <div className="depContainer">
            <div className="simple_header">Оформление доставки</div>
            <div className="depListContainer">
                <div className="dep_item dep_header">
                    <div className="sort_filterContainer"><div>Адрес доставки</div></div>
                    <div className="sort_filterContainer"><div>Дата заказа</div> {/* <img className="sort_filte_img" src={sortTopSVG} /> */} </div>
                    <div className="sort_filterContainer"><div>Дата доставки</div> {/* <img className="sort_filte_img" src={sortBottomSVG} /> */} </div>
                    <div className="sort_filterContainer"><div>Статус</div> {/* <img className="sort_filte_img" src={sortBottomSVG} /> */} </div>
                    {/* <div><DropFilter items={testStatusItems} /></div> */}
                    <div className="sort_filterContainer"><div>Получатель</div></div>
                    <div className="sort_filterContainer"><div>Трек-номер</div> {/* <img className="sort_filte_img" src={sortTopSVG} /> */} </div>
                    <div className="sort_filterContainer"><div>Тип доставки</div></div>
                </div>
                {items}
            </div>
        </div>
    )
}