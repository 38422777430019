import React, { useState } from "react";
import "./hardInput.css";

export default function HardInput(props){
    const [value, setValue] = useState(props.value)
    const changeAction = (e) => {
        setValue(e.target.value)
        
        if (typeof props.changeAction != "undefined"){
            props.changeAction(e.target.value)
        }
    }
    const clickAction = (e) => {
        if (typeof props.clickAction != "undefined"){
            props.clickAction(e.target.value)
        }
    }
    let disabled = typeof props.disabled == "undefined" ? false : props.disabled
    let curVal = typeof props.value == "undefined" ? value : props.value
    return (
        <div className={"hard_input_container " + (props.className ? props.className : "")}>
            <div className="hard_input_title">{props.title}</div>
            <input ref={props.refference} className="hard_input" onChange={(e) => {changeAction(e)}} disabled={disabled} onClick={(e) => {clickAction(e)}} value={curVal}  />
        </div>
    )
}