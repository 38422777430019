export const apiUrl = "https://backend.my-pmi.ru"
//export const apiUrl = "http://127.0.0.1:8080"

let refreshing = false;


export const RegistrationFetch  = (reg_data) => {
  const data = {
    name: reg_data.name,
    surname: reg_data.surname,
    phone: reg_data.phone,
    email: reg_data.email,
    partner_id: reg_data.partner_id,
    password: reg_data.password,
  }
  const url = `${apiUrl}/api/v1/auth/registration`;
  return fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(data),
  })
}

export const ForgetFetch = (forget_data) => {
  const data = {
    partner_id: forget_data.partner_id,
    email: forget_data.email
  }
  const url = `${apiUrl}/api/v1/auth/forget-password`;
  return fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(data),
  })
}

export const DoneForgetFetch = (forget_data) => {
  const data = {
    uid: forget_data.uid,
    new_password: forget_data.new_password,
  }
  const url = `${apiUrl}/api/v1/auth/forget/finish`;
  return fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    })
}

const refresh = () => {
  let mainUrl = `${apiUrl}/api/v1/auth/admin/resfresh`;
  return fetch(mainUrl, {
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({
      refresh_token: localStorage.getItem("refresh_token"),
    }),
  });
};

const fetchMain = (url, type, data = {}, content="json", is_file=false, need_auth=true) => {
  let mainUrl = `${apiUrl}${url}`;
  if (refreshing) {
    return fetchMain(url, type);
  }
  if (localStorage.getItem("access_token") == null && need_auth) {
    window.location.href = "/";
  }
  let headers = {
    authorization: `Bearer: ${localStorage.getItem("access_token")}`
  }
  if (content == "json") {
    headers["Content-Type"] = "application/json;charset=utf-8"
  } else if (content == "pdf"){
    headers["Content-Type"] = "application/pdf"
  }
  let fetchOptions = {
    method: type,
    headers: headers
  };
  if (type != "get") {
    fetchOptions["body"] = JSON.stringify(data);
  } else {
    mainUrl = `${mainUrl}?${new URLSearchParams(data)}`
  }
  return fetch(mainUrl, fetchOptions).then(
    (res) => {
      if (res.status == 401 && need_auth) {
        refreshing = true;
        return refresh()
          .then((res) => {
            if (res.status == 401) {
              refreshing = false;
              window.location.href = "/";
              return;
            }
            return res.json();
          })
          .then((res) => {
            localStorage.setItem("refresh_token", res.data.refresh_token);
            localStorage.setItem("access_token", res.data.access_token);
            refreshing = false;
            return fetchMain(url, type);
          });
      }
      if (is_file){
        return res.blob()
      }
      return res.json();
    },
    (err) => {
      if (err.status == 401) {
      } else {
        alert("Ошибка")
        throw Error(err);
      }
    }
  );
};


export const GetTicketsV2Fetch = (variable) => {
    const data = {
        page: variable.page,
        limit: variable.limit,
        all: variable.all,
        var: {
            is_archived: variable.is_archived,
            event_type: variable.event_type,
            used: variable.used,
            is_accepted: variable.is_accepted
        }
    }
    return fetchMain("/api/v1/admin/tickets/v2/get", "post", data)
}

export const GetTicketsFileFetch = (variable) => {
    const data = {
        page: variable.page,
        limit: variable.limit,
        all: variable.all,
        var: {
            is_archived: variable.is_archived,
            event_type: variable.event_type,
            used: variable.used,
            is_accepted: variable.is_accepted
        }
    }
    return fetchMain("/api/v1/admin/tickets/v2/file", "post", data, "json", true)
}

export const CreateTicketsFetch = (variable) => {
    const data = {
        type: variable.type,
        owner_name: variable.owner_name,
        owner_surname: variable.owner_surname,
        owner_email: variable.owner_email,
        owner_phone: variable.owner_phone,
        owner_login: variable.owner_login,
        num: variable.num,
    }
    return fetchMain("/api/v1/admin/tickets/v2/create", "post", data)
}

export const CreateApplicationFetch = (variable) => {
  //for date fields
  let my_variable = {...variable}
  my_variable.order_create_date = (variable?.order_create_date ? new Date(variable?.order_create_date).toISOString().split("T")[0] : null)
  my_variable.order_send_date = (variable?.order_send_date ? new Date(variable?.order_send_date).toISOString().split("T")[0] : null)
  console.log(my_variable)
  let data = {
    var: my_variable,
    key: "FDHSF(*#3fh2398h!nasf)sdgf21fdsf!"
  }
  return fetchMain("/api/v2/applications/create", "post", data, "json", false, false)
}

export const GetApplicationStatusFetch = ({id, application_hash}) => {
  let data = {
    id: id,
    application_hash: application_hash
  }
  return fetchMain("/api/v2/applications/get-status", "post", data, "json", false, false)
}

export const GetApplicationsFetch = (variable) => {
  const data = {
    page: variable.page,
    limit: variable.limit,
    all: variable.all,
    var: {
        id: variable.id,
        type: variable.type,
        status: variable.status,
        is_accepted: variable.is_accepted,
        removed: variable.removed
    }
  }
  return fetchMain("/api/v2/applications/get", "post", data)
}

export const UpdateApplicationFetch = (variable) => {
  const data = {
    id: variable.id,
    var: {
      status: variable.status,
    }
  }
  return fetchMain("/api/v2/applications/update", "post", data)
}

export const RemoveApplicationFetch = (variable) => {
  const data = {
    id: variable.id
  }
  return fetchMain("/api/v2/applications/remove", "post", data)
}

export const RecoverApplicationFetch = (variable) => {
  const data = {
    id: variable.id
  }
  return fetchMain("/api/v2/applications/recover", "post", data)
}

export const CreateApplicationCommentFetch = (variable) => {
  let data = { 
    id: variable.id,
    comment: {
      text: variable.text,
      type: "user"
    }
  }
  return fetchMain("/api/v2/applications/comment", "post", data)
}

export const AcceptApplicationFetch = (variable) => {
  let data = {
    id: variable.id
  }
  return fetchMain("/api/v2/applications/accept", "post", data)
}
export const GetAccessApplicationFtch = () => {
  return fetchMain("/api/v2/applications/get-admin-access", "post", {})
}


export const AcceptPartnerFetch = (partner_id) => {
  return fetchMain(`/api/v1/admin/accept-partner/${partner_id}`, "post", {})
}

export const GetPartnerTicketsFetch = (data) => {
  return fetchMain("/api/v1/partner/tickets/list", "post", data)
}

export const GetTicketPdfFetch = (ticket_id) => {
  return fetchMain(`/api/v1/partner/tickets/qr/${ticket_id}`, "post", {}, "pdf", true)
}