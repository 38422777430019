export const mainMapper = (store) => {
    return {
        reload: store.main.reload,
        city: store.partner.city,
        street: store.partner.street,
        index: store.partner.index,
        house: store.partner.house,
        flat: store.partner.flat,
        active_input_new_dep: store.partner.active_input_new_dep,

    };
};