import React, { useEffect, useState, useRef, forwardRef } from "react";
import "./points.css";
import { filterTypes } from "./constants";
import { partnerConnector } from "../../connector/connector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';
import vectSVG from "./../../../source/svg/vect.svg"
registerLocale('ru', ru)

export default function Points(props){
    const [curType, SetCurType] = useState(filterTypes[0].value)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [pointsTransactions, setPointsTransactions] = useState([]) 
    const [stratRemains, setStartRemains] = useState(0)
    const [endRemains, setEndRemains] = useState(0)
    const [allIncome, setAllIncome] = useState(0)
    const [allOutCome, setAllOutCome] = useState(0)

    const [isLoading, setIsLoading] = useState(false);
    let typeFilterItems = filterTypes.map(value => 
                    <div onClick={(e) => {SetCurTypeAction(value.value)}} className={"pointTypeFilterItem " + (value.value == curType ? "pointTypeFilterItemActive": "")}>{value.name}</div>
    )
    useEffect(()=>{
        //updatePointsHistoryRequest();
    },[])
    const SetCurTypeAction = (type) => {
        SetCurType(type);
        updatePointsHistoryRequest(startDate, endDate, type);
    }
    const updatePointsHistoryRequest = (sDate = startDate, eDate = endDate, type = curType) => {
        let splitedDate;
        let unpackedSDate;
        let unpackedEDate;
        let dataToSend = {
            type: type,
            page: 0
        }
        if (sDate != null){
            splitedDate = sDate.toLocaleDateString().split(".");
            unpackedSDate = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`
            dataToSend["date_start"] = unpackedSDate;
        }
        if (eDate != null){
            splitedDate = eDate.toLocaleDateString().split(".");
            unpackedEDate = `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`
            dataToSend["date_end"] = unpackedEDate;
        }
        setIsLoading(true)
        partnerConnector.connect_async("getPointsHistory", dataToSend).then(res => {
            setIsLoading(false)
            setEndRemains(res.data[0].total);
            setStartRemains(res.data[res.data.length - 1].total);
            setPointsTransactions(res.data);
            let income = 0
            let outcome = 0
            for (let i = 0; i < res.data.length; ++i){
                if(res.data[i].class == "Списание"){
                    outcome += res.data[i].sum;
                } else {
                    income += res.data[i].sum;
                }
            }
            setAllIncome(income)
            setAllOutCome(-outcome)
        })
    }
    const onChangeDates = (dates) => {
        const [start, end] = dates;
        
        setStartDate(start);
        setEndDate(end);
        if (start && end){
            updatePointsHistoryRequest(start, end)
        }
      };
      const DateInput = forwardRef(({ value, onClick }, ref) => (
        <img src={vectSVG} className="pointHeaderOpenFilter" onClick={onClick} ref={ref} />
      ));
      const MainDateInput = forwardRef(({ value, onClick }, ref) => (
        <input className="mainPointDateFilter" value={value} onClick={onClick} ref={ref} />
      ));
      let pointsItems = pointsTransactions.map(value => 
        <div className="pointTableTr">
            <div>{value.date}</div>
            <div className={(value.class != "Списание" ? "greenIncome" : "redIncome")}>{(value.class != "Списание" ? "+" : "-")}{value.sum} баллов</div>
            <div>{value.class}</div>
            <div>{value.comment}</div>
        </div>
        )
    return (
        <div className="pointContainer">
            <div className="pointNavContainer">
                <div className="simple_header">История баллов</div>
                <div className="pointFilterContainer">
                    <div className="pointPeriodFilter">
                        <div className="pointFilterText">Период:</div>
                        <DatePicker locale="ru" customInput={<MainDateInput/>}  onChange={onChangeDates} startDate={startDate} endDate={endDate} className="pointDate" selectsRange  />
                    </div>
                    <div className="pointTypeFilter">
                        {typeFilterItems}
                    </div>
                    
                    
                </div>
            </div>
            <div className="pointNavAnlitics">
                <div className="pointAnalItem">остаток на начало периода: <span className={(stratRemains >= 0 ? "greenIncome" : "redIncome")}>{stratRemains} б.</span></div>
                <div className="pointAnalItem">остаток на конец периода: <span className={(endRemains >= 0 ? "greenIncome" : "redIncome")}>{endRemains} б.</span></div>
                <div className="pointAnalItem">начисления за период: <span className={(allIncome >= 0 ? "greenIncome" : "redIncome")}>{allIncome} б.</span></div>
                <div className="pointAnalItem">списания за период: <span className={(allOutCome >= 0 ? "greenIncome" : "redIncome")}>{allOutCome} б.</span></div>
            </div>
            
            <div className="pointTable">
                {isLoading && <div className="sceleton"></div>}
                <div className="pointTableTr pointTableHeader">
                    <div className="poinTableHeaderItem">Дата транзакции <DatePicker locale="ru" customInput={<DateInput />} onChange={onChangeDates} startDate={startDate} endDate={endDate} className="pointDate" selectsRange  /></div>
                    <div className="poinTableHeaderItem">Сумма транзакции</div>
                    <div className="poinTableHeaderItem">Тип транзакции</div>
                    <div className="poinTableHeaderItem">Комментарий</div>
                </div>
                <div className="hr"></div>
                {pointsItems}
            </div>
        </div>
    );
}