import React, { useEffect, useState } from "react";
import { CompactTable } from '@table-library/react-table-library/compact';
import { Accordion, Button, Checkbox, DatePicker, Input, InputGroup, InputNumber, Modal, Pagination, Placeholder, SelectPicker, Stack } from "rsuite";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import SearchIcon from '@rsuite/icons/Search';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import { aplication_types, application_statuses, application_statuses_not_accepted, yn_data } from "./constants";
import { AcceptApplicationFetch, apiUrl, CreateApplicationCommentFetch, CreateApplicationFetch, GetAccessApplicationFtch, GetApplicationsFetch, RecoverApplicationFetch, RemoveApplicationFetch, UpdateApplicationFetch } from "../../connector/connectorV2";
import UploaderPM from "../../atoms/UploaderPm/UploaderPm";
import ImageModal from "../../atoms/ImageModal/ImageModal";
import CommentsModal from "../../atoms/CommentsModal/CommentsModal";
import MoveUpIcon from '@rsuite/icons/MoveUp';

export default function Applications(props){
    const [nodes, setNodes] = useState([])
    const [addPop, setAddPop] = useState(false)
    const [removeItem, setRemoveItem] = useState(null)
    const [updateItem, setUpdateItem] = useState(null)
    const [itemToCreate, setItemToCreate] = useState({})
    const [meta, setMeta] = useState(null)
    const [filter, setFilter] = useState({removed: false})
    const [all, setAll] = useState("")
    const [maxPage, setMaxPage] = useState(0)
    const [page, setPage] = useState(0)
    const [access_type, set_access_type] = useState(null)
    const limit = 30
    const theme = useTheme([
        getTheme(),
        {
          Table: `
            --data-table-library_grid-template-columns:  80px 150px 150px 150px 150px 100px 100px 100px 100px 100px;
          `,
        },
      ]);
    const handelCloseAddPop = ()=>{setAddPop(false);setItemToCreate({})}
    const handleOpenAddPop = ()=>{setAddPop(true)}
    const handleCloseRemovePop = ()=>{setRemoveItem(null)}
    const handleOpenRemovePop = (item) =>{setRemoveItem(item)}
    const handleOpenUpdatePop = (item) =>{setUpdateItem(item)}
    const handleCloseUpdatePop = () => {setUpdateItem(null)}
    useEffect(()=>{
        GetItemsAction(page, limit)
        GetAccessApplicationFtch().then(res => {set_access_type(res.data)})
    },[])
    const GetItemsAction = (page, limit) => {
        const data = {
            page: page, limit: limit,
            all: all, status: filter?.status, type: filter?.type, is_accepted: filter?.is_accepted, removed: filter?.removed           
        }
        GetApplicationsFetch(data).then(res => {
            setNodes(res.data)
            setMaxPage(res.meta.max_page)
            setMeta(res.meta)
        })
    }
    const UpdateFilter = (newValue, key) => {
        let newFilter = {...filter}
        newFilter[key] = newValue;
        setFilter(newFilter)
    }
    const UpdateItemField = (newValue, key) => {
        let newItem = {...updateItem}
        newItem[key] = newValue;
        setUpdateItem(newItem)
    }
    const UpdateCreateItemField = (newValue, key) => {
        let newItem = {...itemToCreate}
        newItem[key] = newValue;
        setItemToCreate(newItem)
    }
    const CreateAction = () => {
        if (itemToCreate?.type == null) {
            alert("выберите тип заявки")
            return;
        }
        CreateApplicationFetch(itemToCreate).then(res => {
            handelCloseAddPop()
            setPage(0)
            GetItemsAction(0, limit)
        }) 
    }
    const UpdateAction = () => {
        
        if (updateItem?.type == null) {
            alert("выберите тип заявки")
            return;
        }
        UpdateApplicationFetch(updateItem).then(res => {
            handleCloseUpdatePop()
            setPage(0)
            GetItemsAction(0, limit)
        })
    }
    const RemoveAction = () => {
        RemoveApplicationFetch(updateItem).then(res => {
            handleCloseUpdatePop()
            setPage(0)
            GetItemsAction(0, limit)
        })
    }
    const RecoverAction = (id) => {
        RecoverApplicationFetch({id: id}).then(res => {
            handleCloseUpdatePop()
            setPage(0)
            GetItemsAction(0, limit)
        })
    }
    const COLUMNS = [
        { label: '№', renderCell: (item) => item?.id },
        { label: 'ID партнера', renderCell: (item) => item?.applicant_login },
        { label: 'ФИО', renderCell: (item) => item?.applicant_name },
        { label: 'Email', renderCell: (item) => item?.applicant_email },
        { label: 'Телефон', renderCell: (item) => item?.applicant_phone },
        {
          label: 'Дата',
          renderCell: (item) =>
            item?.create_datetime ? new Date(item?.create_datetime).toLocaleDateString() : null,
        },
        { label: 'Тип', renderCell: (item) => item?.type },
        { label: 'Статус', renderCell: (item) => application_statuses.filter((val)=>val.value == item?.status)[0].label},
        { label: "Управление", renderCell: (item) => 
            <Stack spacing={5}>
                {!item?.is_archived && 
                <>
                    <Button onClick={(e) => {handleOpenUpdatePop(item)}}><EditIcon/></Button>
                </>
                }
                {item?.removed &&
                    <Button onClick={(e) => {RecoverAction(item?.id)}}><MoveUpIcon /></Button>
                }
            </Stack>
        },
        {label: "Подтвержден", renderCell: (item) => (item?.is_accepted ? <div className="round green"></div> : <div className="round red"></div>)},
      ];
    const data = { nodes };
    //additional
    const fileTypes = ["JPG", "JPEG", "PNG"]
    const AcceptApplication = (id) => {
        AcceptApplicationFetch({id: id}).then(res => {
            handleCloseUpdatePop()
            setPage(0)
            GetItemsAction(0, limit)
        })
    }
    return(
        <div className="new_page_wrapper">
            <Stack spacing={15} alignItems="center"><div className="main_title">Заявки</div><Button color="blue" appearance="primary" onClick={handleOpenAddPop}><AddOutlineIcon/></Button></Stack>
            <Stack spacing={20} wrap={true}>
                <Stack spacing={5}><div>тип заявки:</div><SelectPicker data={aplication_types} value={filter?.type} onChange={(val) => {UpdateFilter(val, "type")}} /></Stack>
                <Stack spacing={5}><div>статус заявки:</div><SelectPicker data={application_statuses} value={filter?.status} onChange={(val) => {UpdateFilter(val, "status")}} /></Stack>
                <Stack spacing={5}><div>подтверждена:</div><SelectPicker data={yn_data} value={filter?.is_accepted} onChange={(val) => {UpdateFilter(val, "is_accepted")}} /></Stack>
                <Stack spacing={5}><div>удален:</div><SelectPicker data={yn_data} value={filter?.removed} onChange={(val) => {UpdateFilter(val, "removed")}} /></Stack>
                <Button color="blue" appearance="primary" onClick={(e) => {setPage(0); GetItemsAction(0, limit)}}>найти</Button>
            </Stack>
            <InputGroup>
                <Input value={all} onChange={(val) => setAll(val)} placeholder="общий поиск" />
                <InputGroup.Addon>
                    <SearchIcon onClick={(e) => {setPage(0); GetItemsAction(0, limit)}} />
                </InputGroup.Addon>
            </InputGroup>

            <Pagination total={maxPage*limit +1} limit={limit} activePage={page+1} onChangePage={(page)=>{GetItemsAction(page-1, limit); setPage(page-1)}} />
            <CompactTable columns={COLUMNS} data={data} theme={theme} layout={{ custom: true, horizontalScroll: true }} />

            {/* add pop */}
            <Modal open={addPop} onClose={handelCloseAddPop}>
                <Modal.Header>
                <Modal.Title>Добавить заявку</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack spacing={10} direction="column" alignItems="flex-start">
                        <Stack spacing={5}><div className="w-25">Имя:</div> <Input value={itemToCreate?.applicant_name} onChange={(val) => {UpdateCreateItemField(val, "applicant_name")}} placeholder="имя" /></Stack>
                        <Stack spacing={5}><div className="w-25">Email:</div> <Input value={itemToCreate?.applicant_email} onChange={(val) => {UpdateCreateItemField(val, "applicant_email")}} placeholder="Email" /></Stack>
                        <Stack spacing={5}><div className="w-25">Телефон:</div> <Input value={itemToCreate?.applicant_phone} onChange={(val) => {UpdateCreateItemField(val, "applicant_phone")}} placeholder="Телефон" /></Stack>
                        <Stack spacing={5}><div className="w-25">ID партнера:</div> <Input value={itemToCreate?.applicant_login} onChange={(val) => {UpdateCreateItemField(val, "applicant_login")}} placeholder="Парнерский ID" /></Stack>
                        <Stack spacing={5}><div className="w-25">Тип заявки:</div> <SelectPicker data={aplication_types} value={itemToCreate?.type} onChange={(val) => {UpdateCreateItemField(val, "type")}} placeholder="Тип заявки" /></Stack>
                        <Stack spacing={5}><div className="w-25">Фото коробки:</div> <UploaderPM UploadBaseFileAction={(file) => {UpdateCreateItemField(file, "box_image")}} fileTypes={fileTypes} /></Stack>
                        <Stack spacing={5}><div className="w-25">Фото товара:</div> <UploaderPM UploadBaseFileAction={(file) => {UpdateCreateItemField(file, "order_img")}} fileTypes={fileTypes} /></Stack>
                        <Stack spacing={5}><div className="w-25">Без накладной:</div> <Checkbox checked={itemToCreate?.no_invoice} onChange={(val, boolVal) => {UpdateCreateItemField(boolVal, "no_invoice")}} /></Stack>
                        <Stack spacing={5}><div className="w-25">Фото накладной:</div> <UploaderPM UploadBaseFileAction={(file) => {UpdateCreateItemField(file, "invoice_image")}} fileTypes={fileTypes} /></Stack>
                        <Stack spacing={5}><div className="w-25">Комметарий:</div> <Input as="textarea" rows={3} value={itemToCreate?.type_comment} onChange={(val) => {UpdateCreateItemField(val, "type_comment")}} placeholder="Комметарий" /></Stack>
                        <Stack spacing={5}><div className="w-25">Дата создания заказа:</div> <DatePicker format="yyyy-MM-dd" value={itemToCreate?.order_create_date} onChange={(val) => {UpdateCreateItemField(val, "order_create_date")}} placeholder="Дата создания заказа" /></Stack>
                        <Stack spacing={5}><div className="w-25">Дата отправки заказа:</div> <DatePicker format="yyyy-MM-dd" value={itemToCreate?.order_send_date} onChange={(val) => {UpdateCreateItemField(val, "order_send_date")}} placeholder="Дата отправки заказа" /></Stack>
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={CreateAction} appearance="primary">
                    Добавить
                </Button>
                <Button onClick={handelCloseAddPop} appearance="subtle">
                    Отмена
                </Button>
                </Modal.Footer>
            </Modal>

            {/* remove pop */}
            <Modal open={removeItem!=null} onClose={handleCloseRemovePop}>
                <Modal.Header>
                <Modal.Title>Удалить заявку</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Вы действительно хотите это сделать?
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            {/* update pop */}
            <Modal className="modal_application_update" open={updateItem!=null} onClose={handleCloseUpdatePop}>
                <Modal.Header>
                <Modal.Title>Заявку №{updateItem?.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="scroll_stylle">
                    <Stack className="simple_update_modal_wrapper scroll_stylle" alignItems="flex-start" spacing={20}>
                        <Stack className="values_panel_update scroll_stylle" spacing={10} direction="column" alignItems="flex-start">
                            <Stack spacing={10}><div className="w-25">Имя:</div> <Input readOnly value={updateItem?.applicant_name} placeholder="имя" /></Stack>
                            <Stack spacing={10}><div className="w-25">Email:</div> <Input readOnly value={updateItem?.applicant_email} placeholder="Email" /></Stack>
                            <Stack spacing={10}><div className="w-25">Телефон:</div> <Input readOnly value={updateItem?.applicant_phone} placeholder="Телефон" /></Stack>
                            <Stack spacing={10}><div className="w-25">ID партнера:</div> <Input readOnly value={updateItem?.applicant_login} placeholder="Парнерский ID" /></Stack>
                            <Stack spacing={10}><div className="w-25">Статус заявки:</div> <SelectPicker cleanable={false} searchable={false} data={(updateItem?.is_accepted ? application_statuses : application_statuses_not_accepted)} value={updateItem?.status} onChange={(val) => {UpdateItemField(val, "status")}} placeholder="Статус заявки" /></Stack>
                            <Stack spacing={10}><div className="w-25">Тип заявки:</div> <SelectPicker readOnly data={aplication_types} value={updateItem?.type} placeholder="Тип заявки" /></Stack>
                            <Stack spacing={10}><div className="w-25">Трек номер:</div> <Input readOnly value={updateItem?.application_hash} placeholder="" /></Stack>
                            <Stack spacing={10}><div className="w-25">Фото коробки:</div> <ImageModal value={updateItem?.box_image?.map(img_val => `${apiUrl}/static/${img_val}`)} /></Stack>
                            <Stack spacing={10}><div className="w-25">Фото товара:</div> <ImageModal value={updateItem?.order_img?.map(img_val => `${apiUrl}/static/${img_val}`)} /></Stack>
                            <Stack spacing={10}><div className="w-25">Без накладной:</div> <Checkbox readOnly checked={updateItem?.no_invoice} /></Stack>
                            <Stack spacing={10}><div className="w-25">Фото накладной:</div> <ImageModal value={updateItem?.invoice_image?.map(img_val => `${apiUrl}/static/${img_val}`)} /></Stack>
                            <Stack spacing={10}><div className="w-25">Комметарий:</div> <Input readOnly as="textarea" rows={3} value={updateItem?.type_comment} placeholder="Комментарий" /></Stack>
                            <Stack spacing={10}><div className="w-25">Комметарий партнера:</div> <Input readOnly as="textarea" rows={3} value={updateItem?.user_comment} placeholder="" /></Stack>
                            <Stack spacing={10}><div className="w-25">Дата создания заказа:</div> <DatePicker readOnly value={updateItem?.order_create_date} placeholder="Дата создания заказа" /></Stack>
                            <Stack spacing={10}><div className="w-25">Дата отправки заказа:</div> <DatePicker readOnly value={updateItem?.order_send_date} placeholder="Дата отправки заказа" /></Stack>
                        </Stack>
                        <CommentsModal item={updateItem} get_comments_func={(item) => item?.comments} add_comment={CreateApplicationCommentFetch} get_id_func={(item) => item?.id} get_item_fetch={({id}) => GetApplicationsFetch({page: 0, limit: 100, id: id})}/>
                    </Stack>
                    
                    

                </Modal.Body>
                <Modal.Footer>
                <Button onClick={(e) => {UpdateAction()}} appearance="primary">
                    Сохранить
                </Button>
                <Button onClick={(e) => {RemoveAction()}} appearance="primary">
                    Удалить
                </Button>
                {updateItem?.is_accepted != true && access_type == "superadmin" && 
                    <Button onClick={(e) => {AcceptApplication(updateItem?.id)}} appearance="primary">
                        Подтвердить
                    </Button>
                }
                <Button onClick={handleCloseUpdatePop} appearance="subtle">
                    Отмена
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}