import React, { useState } from "react";
import { Modal } from "rsuite";

export default function ImageModal(props){
    const [open, setOpen] = useState(false)
    const [curImage, setCurImage] = useState(null)
    const handleOpen = (img) => {setCurImage(img);setOpen(true)}
    const handleClose = () => {setCurImage(null); setOpen(false)}
    return (
        <>
            <div className="modal_images_pre_wrapper">
                {props.value?.map(val => <img onClick={(e) => {handleOpen(val)}} src={val} className="modal_pre_image" />)}
            </div>
            
            
            <Modal open={open} onClose={handleClose} className="image_modal_modal">
                <Modal.Header><Modal.Title>Изобажение</Modal.Title></Modal.Header>
                <Modal.Body>
                    <img src={curImage} className="modal_body_img" />
                </Modal.Body>
            </Modal>
        </>
    )
}